import React from "react";
import MainSideBar from "../../components/mainSidebar/MainSideBar";
import HypsoHeader from "../../components/header/HypsoHeader";
import HypsoFooter from "../../components/footer/HypsoFooter";
import { useState, useEffect } from "react";

function Layout({ children }) {
  const [showSidebar, setShowSidebar] = useState(true);
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);

  // console.log("showSidebar =>", showSidebar);
  // console.log("showHeaderFooter =>", showHeaderFooter);

  const updateVisibility = () => {
    const path = window.location.pathname;
    const shouldShow = !["/login", "/logout", "/forgot-password","/otp-capture","/set-new-password","/confirm-email"].includes(
      path
    );
    setShowSidebar(shouldShow);
    setShowHeaderFooter(shouldShow);
  };

  useEffect(() => {
    // Set the local storage key and value
    var is404Page = localStorage.getItem("is404Page");

    if (is404Page === "true") {
      // console.log("IF =>");
      setShowSidebar(false);
      setShowHeaderFooter(false);
      localStorage.removeItem("is404Page");
    } else {
      // console.log("ELSE =>");
      updateVisibility();
    }
  }, [children]);

  return (
    <>
      {showSidebar && <MainSideBar />}
      {showHeaderFooter && <HypsoHeader />}
      <main>{children}</main>
      {showHeaderFooter && <HypsoFooter />}
    </>
  );
}
export default Layout;
