import React, { useState, useEffect } from "react";
import { useUser } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { callApi } from "../api/Api";
import SingleImageSelector from "../singleImageSelector/SingleImageSelector";
import Message from "../errors/Message";
import { GetAreas } from "../../utils/getareas";

const AddAdminUser = () => {
  const [formValues, setFormValues] = useState({
    role_code: "",
    admin_areas: [],
  });
  const [featuredImage, setFeaturedImage] = useState("");
  const { user, setUser } = useUser();
  const [errors, setErrors] = useState([]);
  const [areas, setAreas] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    GetAreas();
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
  
    if (name === "role_code") {
      if (value === "A-ADM") {
        setFormValues({
          ...formValues,
          [name]: value,
          admin_areas: formValues.admin_areas.includes(1) ? formValues.admin_areas : [1], // Add ID 1 only if not already selected
        });
      } else {
        setFormValues({
          ...formValues,
          [name]: value,
          admin_areas: [], // Clear areas
        });
      }
    } else if (name === "admin_areas") {
      const areaId = parseInt(value, 10);
      const updatedAreas = checked
        ? [...formValues.admin_areas, areaId] // Add area if checkbox is checked
        : formValues.admin_areas.filter((id) => id !== areaId); // Remove area if unchecked
  
      setFormValues({
        ...formValues,
        admin_areas: updatedAreas,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };
  
  

  // handleChange for featuredImages
  const onChangeFeaturedImage = (newlySelectedImage) => {
    // setFeaturedImage(newlySelectedImage.image_id)
    setFeaturedImage(newlySelectedImage);
  };
  // Toast for Shop Add
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
  const handleSubmit = async (e) => {
    e.preventDefault();

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    var payload = {
      ...formValues,
      profile_image: featuredImage.image_id || null,
      is_active: true,
    };
    console.log(payload);

    try {
      const UserResponse = await callApi(
        `${baseUrl}/modules/users/create`,
        "POST",
        headers,
        payload
      );
      console.log("UserResponse", UserResponse);

      if (UserResponse.success) {
        console.log("success", UserResponse);
        Toast.fire({
          icon: "success",
          title: "Data saved successfully",
        });
        navigate(`/confirm-admin?email=${formValues?.email}`);
      } else {
        setErrors(UserResponse.errors);
        console.error("Error: Response indicates failure", UserResponse);
        Toast.fire({
          icon: "error",
          title: UserResponse.message || "Failed to save data",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        icon: "error",
        title: "An error occurred while adding the user",
      });
    }
  };

  async function GetAreas() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    try {
      const areaData = await callApi(
        `${baseUrl}/modules/areas/get-areas`,
        "POST",
        headers
      );
      setAreas(areaData?.records);
    } catch (error) {
      console.error("There was an error fetching the area!", error);
    }
  }
  console.log("formValues", formValues);

  return (
    <div>
      <div className="page-heading">
        <div className="page-title">
          <section className="section">
            <div className="match-height row">
              <div className="col-lg-10 col-md-10">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Create Admin User</h4>
                  </div>
                  <div className="card-content">
                    <div className="card-body">
                      <form
                        className="form-horizontal form"
                        onSubmit={handleSubmit}
                      >
                        <div className="form-body">
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                First Name
                              </label>
                            </div>
                            <div className="col-md-8 form-group">
                              <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                className="form-control"
                                value={formValues?.first_name || ""}
                                onChange={handleChange}
                              />
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"first_name"}
                              />
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                Last Name
                              </label>
                            </div>
                            <div className="col-md-8 form-group">
                              <input
                                type="text"
                                id="last_name"
                                name="last_name"
                                className="form-control"
                                value={formValues?.last_name || ""}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                Phone
                              </label>
                            </div>
                            <div className="col-md-8 form-group">
                              <input
                                type="text"
                                id="phone"
                                name="phone"
                                className="form-control"
                                value={formValues?.phone || ""}
                                onChange={handleChange}
                              />
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"phone"}
                              />
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                Email
                              </label>
                            </div>
                            <div className="col-md-8 form-group">
                              <input
                                type="text"
                                id="email"
                                name="email"
                                className="form-control"
                                value={formValues?.email || ""}
                                onChange={handleChange}
                              />
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"email"}
                              />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                Select Role
                              </label>
                            </div>
                            <div className="col-md-8">
                              <fieldset className="form-group">
                                <select
                                  name="role_code"
                                  value={formValues?.role_code || ""}
                                  className="form-select"
                                  onChange={handleChange}
                                >
                                  <option value="" disabled selected>
                                    Select role
                                  </option>
                                  <option value="S-ADM">Super-Admin</option>
                                  <option value="A-ADM">Area-Admin</option>
                                </select>
                              </fieldset>
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"role_code"}
                              />
                            </div>

                            {formValues.role_code === "A-ADM" && (
                              <div className="row">
                                <label htmlFor="areas" className="col-md-4">
                                  Select Area
                                </label>
                                <div className="col-md-8">
                                  <ul className="list-unstyled">
                                    {areas.map((area, i) => (
                                      <li
                                        key={i}
                                        className="d-inline-block mb-2 me-3"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={`area-${i}`}
                                          name="admin_areas"
                                          value={area.area_id}
                                          checked={formValues.admin_areas.includes(
                                            area.area_id
                                          )}
                                          onChange={handleChange}
                                        />
                                        <label
                                          htmlFor={`area-${i}`}
                                          className="ms-2"
                                        >
                                          {area.name}
                                        </label>
                                      </li>
                                    ))}
                                  </ul>
                                  <Message
                                    type={"error"}
                                    errors={errors}
                                    fieldName={"areas"}
                                  />
                                </div>
                              </div>
                            )}

                            <div className="mt-3 col-md-4">
                              <label htmlFor="gender">Gender</label>
                            </div>
                            <div className="d-flex justify-content-start align-items-center gap-3 mt-3 col-md-8 form-group">
                              <div className="d-flex gap-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="male"
                                  value="Male"
                                  checked={formValues.gender === "Male"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="male"
                                >
                                  Male
                                </label>
                              </div>

                              <div className="d-flex gap-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="female"
                                  value="Female"
                                  checked={formValues.gender === "Female"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="female"
                                >
                                  Female
                                </label>
                              </div>

                              <div className="d-flex gap-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="others"
                                  value="others"
                                  checked={formValues.gender === "others"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="others"
                                >
                                  Others
                                </label>
                              </div>
                            </div>

                            <div className="mt-2 col-md-4">
                              <label htmlFor="first-name-horizontal">
                                Upload Profile
                              </label>
                            </div>
                            <div className="mt-2 col-lg-8 col-md-8 col-xs-8 form-group">
                              <fieldset>
                                <SingleImageSelector
                                  imageToShow={featuredImage}
                                  onChangeImage={onChangeFeaturedImage}
                                />
                              </fieldset>
                            </div>
                            <div className="d-flex justify-content-end col-sm-12">
                              <button
                                type="submit"
                                className="mt-1 btn btn-primary me-1"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddAdminUser;
