import React from 'react';

const Pagination = ({ totalPage, setCurrentPage, currentPage }) => {
  const handleNextPage = (event) => {
    event.preventDefault()
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPage?.length));
  };

  const handlePageChange = (event, page) => {
    event.preventDefault()
    setCurrentPage(page);
  };

  const handlePreviousPage = (event) => {
    event.preventDefault()
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const renderPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5; // Number of visible pages

    if (totalPage?.length <= maxVisiblePages) {
      // If total pages are less than max visible pages, show all
      totalPage.forEach((number, index) => {
        pages.push(
          <li
            key={index}
            style={{cursor:'pointer'}}
            className={`page-item ${currentPage === number ? 'active' : ''}`}
          >
            <span className="page-link" onClick={(e) => handlePageChange(e, number)}>
              {number}
            </span>
          </li>
        );
      });
    } else {
      // Show first page, current page, and dots when necessary

      const startPage = Math.max(1, currentPage - 1);
      const endPage = Math.min(totalPage?.length - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <li
            key={i}
            style={{cursor:'pointer'}}
            className={`page-item ${currentPage === i ? 'active' : ''}`}
          >
            <span className="page-link" onClick={(e) => handlePageChange(e, i)}>
              {i}
            </span>
          </li>
        );
      }

      if (currentPage < totalPage?.length - 2) {
        pages.push(
          <li style={{cursor:'pointer'}} key="end-dots" className="page-item">
            <span className="page-link">...</span>
          </li>
        );
        pages.push(
          <li style={{cursor:'pointer'}} key={totalPage?.length} className="page-item">
            <span className="page-link" onClick={(e) => handlePageChange(e, totalPage?.length)}>
              {totalPage?.length}
            </span>
          </li>
        );
      }
    }

    return pages;
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination pagination-primary ">
        <li className="page-item">
          <button className="page-link" onClick={handlePreviousPage}  disabled={currentPage === 1}>
          <span aria-hidden="true"><i className="bi bi-chevron-left"></i></span>
          </button>
        </li>
        {renderPageNumbers()}
        <li className="page-item">
          <button className="page-link" onClick={handleNextPage}  disabled={currentPage === totalPage?.length}>
          <span aria-hidden="true"><i className="bi bi-chevron-right"></i></span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
