import React, { useState, useEffect } from "react";
import { useQuill } from 'react-quilljs'; // Importing Quill.js hook
import "./TextEditor.css" // Importing your custom CSS
import Quill from 'quill'; // Importing Quill library

// Define the TextEditor component
const TextEditor = ({ onChangeTextEditorValue, productDescription }) => {
  
    // Use the Quill hook to get access to the editor instance
    const { quill, quillRef } = useQuill();
    const [value, setValue] = useState(""); // Create a state variable to hold the editor's content

    useEffect(() => {
        require('quill/dist/quill.snow.css');
    }, []);

    useEffect(() => {
        if (quill) {
            quill.focus(false)

            if (productDescription && Object.keys(productDescription)?.length != 0) {
                // Set the initial content of the Quill editor here
                quill.clipboard.dangerouslyPasteHTML(productDescription); // Paste initial content
            }

            quill.on('text-change', () => {
                // Get the HTML content from the editor and update the state and parent component
                const editorHTML = quillRef.current.firstChild.innerHTML;
                // console.log(editorHTML);
                setValue(editorHTML);
                onChangeTextEditorValue(editorHTML);
            });

            // Disable the image handler (toolbar option) in Quill
            quill.getModule('toolbar').container.querySelectorAll('.ql-image').forEach((item) => {
                item.style.display = 'none';
            });

            // Customize the behavior of the Enter key to add a line break
            quill.keyboard.addBinding({
                key: Quill.sources.USER,
                keyCode: 13,  // Enter key
                handler: function (range, context) {
                    const currentLeaf = quill.getLeaf(range.index);
                    quill.insertEmbed(range.index, 'br', true, Quill.sources.USER); // Insert a line break
                    quill.setSelection(range.index + 1, Quill.sources.SILENT); // Set the cursor position
                }
            });
        }
    }, [quill, quillRef, productDescription]); // Run this effect when quill, quillRef, or description change

    // console.log("TextEditor - values",value)
    // console.log("productDescription",productDescription)
    // Render the Quill editor within a containing div
    return (
        <div className="textEditorContainor">
            <div  ref={quillRef} />
        </div>
    );
}

export default TextEditor;