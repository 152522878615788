import React, { useEffect, useState, useContext } from "react";
import { callApi } from "../../api/Api";
import "./AddTags.css";
import { UserContext } from "../../../contexts/UserContext";
import Message from "../../errors/Message";

//received a props from AddShop and EditShop
function AddTags({
  addedTags,
  setAddedTags,
  removedTags,
  setRemovedTags,
  errors,
  setFormValues,
  name
}) {
  const [inputTag, setInputTag] = useState('');
  const [filteredTags, setFilteredTags] = useState([]);
  const { user, setUser } = useContext(UserContext);
  const getTags = async () => {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    // const tag_url = `${baseUrl}/modules/tags/get-all-tags`

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      filters: {
        conditions: [
          {
            field: "name",
            operator: "like",
            value: inputTag,
            dataType: "string",
          },
        ],
      },
    };

    try {
      const getTagsResponse = await callApi(
        `${baseUrl}/modules/tags/get-all-tags`,
        "POST",
        headers,
        payload
      );
      const tags = getTagsResponse.records;
      setFilteredTags(tags);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (inputTag.trim()) {
      getTags();
    }
  }, [inputTag]);

  // Add tag to formValues.tags, not tagsAttachedToShop
  const addTag = (tag) => {
    if (!addedTags.some((addedTag) => addedTag.name === tag.name)) {
      const updatedAddedTags = [...addedTags, tag];
      setAddedTags(updatedAddedTags);
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        tags: {
          added_tags: updatedAddedTags,
        },
      }));
      setInputTag('')
      setFilteredTags([])
    }
  };

  const processAndSetToRemovedTags = (tag) => {
    if (!removedTags.some((removedTag) => removedTag?.name === tag?.name)) {
      setRemovedTags((prevTags) => [...prevTags, tag]);
    }
  };

  const onKeyDownEvent = (e) => {
    const { key } = e;
    const trimmedInput = inputTag.trim();
    if (key === "Enter" && trimmedInput.length > 0) {
      e.preventDefault();
      const newTagToBeAdded = { tag_id: 0, name: trimmedInput };
      addTag(newTagToBeAdded);
      setInputTag('');
    }
  };

  const deleteTag = (tag) => {
    const updatedAddedTags = addedTags.filter(
      (addedTag) => addedTag.name !== tag.name
    );
    setAddedTags(updatedAddedTags);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      tags: {
        added_tags: updatedAddedTags,
      },
    }));
    processAndSetToRemovedTags(tag);
  };

  return (
    <div>
      <div className="input-tag">
        <div className="input-wrapper">
          <input
            type="text"
            id="first-name-horizontal"
            className="form-control"
            name="tags"
            value={inputTag}
            onChange={(e) => setInputTag(e.target.value)}
            onKeyDown={onKeyDownEvent}
            placeholder={`Add tags relevant to your ${name}`}
            required
          />
          <Message type={"error"} errors={errors} fieldName={"tag_id"} />
          <ul className="tagUl">
            {inputTag.length > 0 &&
              filteredTags.map((tag) => (
                <li
                  key={tag.tag_id}
                  className="dropdownTag"
                  onClick={() => addTag(tag)}
                >
                  {tag.name}
                </li>
              ))}
          </ul>
        </div>
        <ul className="input-tag__tags">
          {addedTags.map((tag, index) => (
            <li className="" key={index}>
              {tag.name}
              <button type="button" onClick={() => deleteTag(tag)}>
                <i className="bi bi-x"></i>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AddTags;
