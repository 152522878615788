import React, { useState, useEffect } from "react";
import { callApi } from "../api/Api";
import { useUser } from "../../utils/utils";
import Swal from "sweetalert2";

function SelectedAreaDropdown() {
  const { user, setUser } = useUser();
  const [selectedArea, setSelectedArea] = useState();
  const [areaDetails, setAreaDetails] = useState([]);
  const [userAreaInApi, setUserAreaInApi] = useState();
  const [filteredArea, setFilteredArea] = useState([]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  // useEffect for getArea
  useEffect(() => {
    getArea();
  }, []);

  // useEffect for updateUserAreaId
  useEffect(() => {
    if (selectedArea) {
      updateUserAreaId();
    }
  }, [selectedArea]);

  // useEffect for userDataFetch
  useEffect(() => {
    if (user) {
      userDataFetch();
    }
  }, [user?.user_id]);

  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

  async function getArea() {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    var payload = {
      user_id: user?.user_id,
    };
    try {
      const areaData = await callApi(
        `${baseUrl}/modules/areas/get-areas-for-admin`,
        "POST",
        headers,
        payload
      );

      setAreaDetails(areaData.records);
    } catch (error) {
      console.error("There was an error fetching the area!", error);
    }
  }

  const updateUserAreaId = async () => {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    const payload = {
      user_id: user?.user_id,
      last_used_area: user?.area_id || 1,
    };

    try {
      const areaUpdate = await callApi(
        `${baseUrl}/modules/users/update`,
        "POST",
        headers,
        payload
      );
      if (areaUpdate.success) {
        Toast.fire({
          icon: "success",
          title: "Area updated successfully",
        });
      }
      // console.log(areaUpdate);
    } catch (error) {
      console.log("error", error);
    }
  };

  const userDataFetch = async () => {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    try {
      const userData = await callApi(
        `${baseUrl}/modules/users/get-user/${user?.user_id}`,
        "POST",
        headers
      );
      // console.log("user=>", userData);
      setUserAreaInApi(userData?.record?.last_used_area);
    } catch (error) {
      console.log("errors", error);
    }
  };

  const handleAreaChange = (event) => {
    const newSelectedArea = event.target.value;
    setSelectedArea(newSelectedArea);
    const updatedUser = { ...user, area_id: newSelectedArea };
    setUser(updatedUser);
    console.log("check the updated user", user);

    localStorage.setItem("user", JSON.stringify(updatedUser));

    // Automatically refresh the page after setting the new area
    // window.location.reload();
  };



  return (
    <div>
      <fieldset className="form-group">
        <select
          name="area"
          className="form-select"
          onChange={handleAreaChange}
          value={selectedArea || userAreaInApi || user?.area_id || ""}
        >
          {areaDetails?.length > 0 ? (
            areaDetails.map((area) => (
              <option key={area?.area_id} value={area?.area_id}>
                {area.name}
              </option>
            ))
          ) : (
            <option value="" disabled>
              No areas available
            </option>
          )}
        </select>
      </fieldset>
    </div>
  );
}

export default SelectedAreaDropdown;
