import { jwtDecode } from "jwt-decode";

export const getTokenDetails = (token) => {
    // console.log("token" ,token);
    if (!token) return null;
    try {
        if (token) {
            const decoded = jwtDecode(token);
            return decoded;
        }
    } catch (error) {
        console.error("Failed to decode token", error);
        return null;
    }
};