import React, { useState, useEffect } from "react";
import { useUser } from "../../../utils/utils";
import { callApi } from "../../api/Api";
import loading_gif from "../../../assets/gif/loading_gif.gif";
import { Link } from "react-router-dom";
import Pagination from "../../pagination/Pagination";
import customer_service from "../../../assets/png/customer_service.png";
import Swal from "sweetalert2";
import "./categoryDataTable.css";

const CategoryDataTable = () => {
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState("ENT_SHOP");
  const { user } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const columnData = ["S.No", "Image", "Name", "Status"];
  useEffect(() => {
    categoryList(status);
  }, [currentPage, status, search]);

  async function categoryList(status) {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      entity_type: status,
      page_size: 10,
      current_page: currentPage,
      filters: {
        conditions: [
          {
            field: "c.name",
            operator: "like",
            value: search,
            dataType: "string",
          },
        ],
      },
    };
    setIsLoading(true);
    try {
      const categories = await callApi(
        `${baseUrl}/modules/categories/by-entity`,
        "POST",
        headers,
        payload
      );

      setCategories(categories.records);
      const pageTotal = categories.total_pages;
      setTotalPage(Array.from({ length: pageTotal }, (_, i) => i + 1));
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    } finally {
      setIsLoading(false);
    }
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const handleEventDelete = async (id) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmation.isConfirmed) {
      const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

      console.log("your category ID", id);
      const headers = {
        "Content-Type": "application/json",
        token: user?.token,
      };
      try {
        const DeleteResponse = await callApi(
          `${baseUrl}/modules/categories/delete/${id}`,
          "POST",
          headers
        );
        console.log("DeleteResponse", DeleteResponse);

        if (DeleteResponse.success) {
          console.log("success", DeleteResponse);
          Toast.fire({
            title: "Deleted",
            icon: "success",
          });
          // Remove deleted service from the list
          setCategories(
            categories.filter((category) => category.category_id !== id)
          );
        } else {
          console.log("failed", DeleteResponse);
          Toast.fire({
            title: "Failed to delete category",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        Toast.fire({
          title: "An error occurred while deleting the category",
          icon: "error",
        });
      }
    }
  };
  console.log("categories", categories);

  return (
    <>
      <div className="d-flex justify-content-between mb-3 row">
        <div className="d-flex justify-content-between align-items-center gap-2col-12 col-md-12">
          <h3>Categories</h3>
          <Link to={"/category-new"}>
            <button className="btn btn-primary btn-sm">Add Category</button>
          </Link>
        </div>
      </div>
      <div className="page-heading">
        <div className="page-title">
          <section className="section">
            <div className="card">
              <div className="card-body">
                <div className="dataTable-top d-flex justify-content-between mb-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="active nav-link"
                        id="home-tab"
                        data-bs-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={() => setStatus("ENT_SHOP")}
                      >
                        Shop
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => setStatus("ENT_SERVICE")}
                      >
                        Service
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={() => setStatus("ENT_EVENT")}
                      >
                        Event
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="property-tab"
                        data-bs-toggle="tab"
                        href="#property"
                        role="tab"
                        aria-controls="property"
                        aria-selected="false"
                        onClick={() => setStatus("ENT_PROPERTY")}
                      >
                        Property
                      </a>
                    </li>
                  </ul>
                  <div className="search-conatiner form-group">
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control"
                      placeholder="Search Category"
                      name=""
                      id=""
                    />
                    <div className="search-icon">
                      <i className="bi bi-search"></i>
                    </div>
                  </div>
                </div>
                <div className="dataTable-container">
                  <table className="table table-striped" id="table1">
                    <thead>
                      <tr>
                        {columnData.map((col, i) => (
                          <th className="ta_head" key={i}>
                            {col}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {isLoading && (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                      {categories && categories.length > 0 ? (
                        categories?.map((category, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <div>
                                <img
                                  src={
                                    category.featured_image_path
                                      ? category.featured_image_path
                                      : customer_service
                                  }
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            </td>
                            <td className="text-capitalize">{category.name}</td>
                            <td>
                              {category.is_active == true
                                ? "Active"
                                : "Inactive"}
                            </td>
                            <td>
                              <div className="d-flex justify-content-end col-sm-12">
                                <Link
                                  to={{
                                    pathname: `/category-edit/${category.category_id}`,
                                    state: category,
                                  }}
                                >
                                  <button className="mb-1 btn btn-primary me-1">
                                    <i className="bi bi-pencil-square"></i>
                                  </button>
                                </Link>
                                <button
                                  className="mb-1 btn btn-danger me-1"
                                  onClick={() =>
                                    handleEventDelete(category.category_id)
                                  }
                                  title="Delete"
                                >
                                  <i className="bi bi-trash3"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={columnData.length}>No data found.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="dataTable-bottom d-flex justify-content-end">
                  {Array.isArray(categories) && totalPage?.length > 1 ? (
                    <Pagination
                      totalPage={totalPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default CategoryDataTable;
