import React, { useEffect, useState } from 'react'
import { useUser } from '../../utils/utils';
import { callApi } from '../api/Api';

const AddListing = ({ customerToken, setCustomerToken, setCustomerMobileNumber , type }) => {
    const [userList, setUserList] = useState([]);
    const { user, setUser } = useUser();
    const [userId, setUserId] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [userInfo, setUserInfo] = useState();
    const [showUserData, setShowUserData] = useState(false)

    useEffect(() => {
        FetchUserList();
    }, []);

    async function FetchUserList() {
        var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };

        var payload = {
            filters: {
                conditions: [
                  {
                    field: "u.role_code",
                    operator: "equals",
                    value: "CSTR",
                    dataType: "string",
                  },
                ],
              },
        }

        setIsLoading(true);
        try {
            const userListData = await callApi(
                `${baseUrl}/modules/users/get-users`,
                "POST",
                headers,
                payload
            );

            setUserList(userListData.records);
        } catch (error) {
            console.error("There was an error fetching the data!", error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchUserData(userId);
    }, [userId]);

    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const fetchUserData = async (userId) => {

        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };

        try {
            const userDataDetails = await callApi(
                `${baseUrl}/modules/users/get-user/${userId}`,
                "POST",
                headers
            );
            // console.log("userData", userDataDetails);
            setUserInfo(userDataDetails.record);
            setCustomerToken(userDataDetails?.record?.authtoken)
            setCustomerMobileNumber(userDataDetails?.record?.phone)
            setShowUserData(true)
        } catch (error) {
            console.log("errors", error);
        }

    };

    // console.log("user", userInfo);

    // console.log("id", userId);





    return (
        <>

            <section id="basic-horizontal-layouts">
                <div className="match-height row">
                    <div className="col-lg-10 col-md-10">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">{`Establish a ${type} for the user`}</h4>
                            </div>
                            <div className="card-content">
                                <div className="card-body">
                                    <form className="form-horizontal form">
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label htmlFor="first-name-horizontal">Select User</label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <fieldset className="form-group">
                                                        <select
                                                            value={userId}
                                                            name=""
                                                            className="form-select"
                                                            onChange={e => setUserId(e.target.value)}
                                                        >
                                                            <option value="" disabled selected>
                                                                Select user
                                                            </option>
                                                            {
                                                                userList.map((user, i) => (
                                                                    <option value={user?.user_id} key={i}>{user?.first_name} {user?.last_name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <div>

                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                userInfo != undefined && showUserData && (
                    <section id="basic-horizontal-layouts">
                        <div className="match-height row">
                            <div className="col-lg-10 col-md-10">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">User Details</h4>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <form className="form-horizontal form">
                                                <div className="form-body">
                                                    <div className="row">
                                                        <div className="col-md-4" style={{ marginBottom: '15px' }}>
                                                            <label htmlFor="first-name-horizontal">Username</label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            {userInfo?.first_name} {userInfo?.last_name}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4" style={{ marginBottom: '15px' }}>
                                                            <label htmlFor="first-name-horizontal">Email</label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            {userInfo?.email}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4" style={{ marginBottom: '15px' }}>
                                                            <label htmlFor="first-name-horizontal">Phone</label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            {userInfo?.phone}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }
        </>
    )
}

export default AddListing
