import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { callApi } from "../../api/Api";
import Swal from "sweetalert2";
import AddTags from "../../Tags/AddTags/Addtags";
import WorkingHours from "../../workingHours/WorkingHours";
import "../../Tags/EditTags/EditTags.css";
import SingleImageSelector from "../../singleImageSelector/SingleImageSelector";
import CitySelector from "../../selector/CitySelector";
import ShopCategorySelector from "../../selector/ShopCategorySelector";
import MultiImageSelector from "../../multiImageSelector/MultiImageSelector";
import { useUser } from "../../../utils/utils";
import StateSelector from "../../selector/StateSelector";
import MapComponent from "../../mapSelector/MapComponent";
import { defaultLocation } from "../../../utils/locationUtils";
import AreaSelector from "../../selector/AreaSelector";
import MobileNumberVerified from "../../mobileNumberVerified/MobileNumberVerified";
import TextEditor from "../../richtext/TextEditor";
import Coordinates from "../../mapSelector/Coordinates";

function EditShop() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [errors, setErrors] = useState([]);
  const [formValues, setFormValues] = useState({ address: {} });
  const [shopInfo, setShopInfo] = useState({});
  const [phoneNumber, setPhoneNumber] = useState();
  const [newPhoneNumber, setNewPhoneNumber] = useState(formValues?.phone);
  const [userInfo, setUserInfo] = useState({});
  const [phoneVerifyModal, setPhoneVerifyModal] = useState(false);
  const { user, setUser } = useUser();
  const [addedTags, setAddedTags] = useState([]);
  const [removedTags, setRemovedTags] = useState([]);
  const [featuredImage, setFeaturedImage] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [rejectReason, setRejectReason] = useState({});
  const [isFeatured, setIsFeatured] = useState();
  const [metaImage, setMetaImage] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [showLatitude, setShowLatitude] = useState(
    defaultLocation[0]?.latitude || 0
  );
  const [showLongitude, setShowLongitude] = useState(
    defaultLocation[0]?.longitude || 0
  );
  const [isEditMode, setIsEditMode] = useState(false);

  const image_id = selectedImages?.length
    ? selectedImages.map((selectImage) => selectImage.image_id)
    : [];

  const [description, setDescription] = useState("");
  const [coordinates, setCoordinates] = useState("");

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [workingHours, setWorkingHours] = useState(
    days.map((day) => ({
      day,
      start: {
        time: "",
        session: "",
      },
      end: {
        time: "",
        session: "",
      },
      status: false, //Initially, none of the states of active
    }))
  );

  //Toast for EditShop
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const [workingTime, setWorkingTime] = useState([]);

  useEffect(() => {
    setWorkingTime(
      workingHours.map((day) =>
        day.status
          ? day // Keep the times as they are if status is true
          : {
              ...day,
              start: { time: "", session: "" },
              end: { time: "", session: "" },
            }
      )
    );
  }, [workingHours]);

  // Update phoneNumber whenever formValues.phone changes
  useEffect(() => {
    setNewPhoneNumber(formValues.phone || "");
  }, [formValues.phone]);

  function updateGeoLocation(lat, long) {
    setLatitude(parseFloat(lat));
    setLongitude(parseFloat(long));
  }

  useEffect(() => {
    if (coordinates) {
      const [lat, long] = coordinates.split(",").map((coord) => coord.trim());
      if (!isNaN(lat) && !isNaN(long)) {
        setLatitude(parseFloat(lat));
        setLongitude(parseFloat(long));
        setShowLatitude(parseFloat(lat));
        setShowLongitude(parseFloat(long));
      } else {
        console.error(
          'Invalid coordinates format. Please use "latitude, longitude".'
        );
      }
    }
  }, [coordinates]);

  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

  useEffect(() => {
    async function fetchShopData() {
      var headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: user?.token,
      };
      try {
        const shopData = await callApi(
          `${baseUrl}/modules/shops/get-shop/${id}`,
          "POST",
          headers
        );
        if (!shopData || !shopData.record) {
          navigate("/*");
          console.log("no");
        }
        setShopInfo(shopData.record);
        updateGeoLocation(
          shopData.record.geo_location.latitude,
          shopData.record.geo_location.longitude
        );
        setWorkingHours(
          shopData.record.shop_timings.working_hours || workingHours
        );
        setFeaturedImage(shopData.record.featured_image);
        setMetaImage(shopData?.record?.meta_image_details)
        setSelectedImages(
          Array.isArray(shopData?.record?.images) ? shopData.record.images : []
        );
        setStatus(shopData.record.status);
        setIsFeatured(shopData.record.is_featured);
        setPhoneNumber(shopData.record.phone);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    }

    fetchShopData();
  }, [id]);

  // handle rich text editor html
  const onChangeTextEditorValue = (value) => {
    setDescription(value);
  };

  useEffect(() => {
    userDataFetch();
  }, []);

  const userDataFetch = async () => {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    try {
      const userData = await callApi(
        `${baseUrl}/modules/users/get-user/${user?.user_id}`,
        "POST",
        headers
      );
      setUserInfo(userData.record);
    } catch (error) {
      console.log("errors", error);
    }
  };

  const deleteTag = (deleteTag) => {
    // Find the index of the tag to be removed
    const updatedTagIndex = shopInfo.tags.findIndex(
      (tag) => tag.tag_id === deleteTag.tag_id
    );

    // If the tag is found, remove it
    if (updatedTagIndex !== -1) {
      // Create a new array without the removed tag
      const newTags = [
        ...shopInfo.tags.slice(0, updatedTagIndex),
        ...shopInfo.tags.slice(updatedTagIndex + 1),
      ];
      // Update the formValues state with the modified array
      setShopInfo({ ...shopInfo, tags: newTags });
      // Add the removed tag to the removedTags state
      setRemovedTags((prevRemovedTags) => [...prevRemovedTags, deleteTag]);
    }
  };

  const handleAddTag = (tag) => {
    //adds a tag to the tags array in the formValues state.
    setShopInfo((prevFormValues) => ({
      ...prevFormValues,
      tags: [...prevFormValues.tags, tag],
    }));
    //updates the addedTags state
    setAddedTags((prevAddedTags) => [...prevAddedTags, tag]);
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleAddressChange = (e) => {
    var updatedAddress = {
      ...formValues.address,
      [e.target.name]: e.target.value,
    };
    console.log("Updated Address - " + JSON.stringify(updatedAddress));

    setFormValues({
      ...formValues, // Keep the existing form values
      address: updatedAddress, // Update only the address field
    });
  };

  // handleChange for featuredImages
  const onChangeFeaturedImage = (newlySelectedImage) => {
    setFeaturedImage(newlySelectedImage);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      featured_image: newlySelectedImage?.image_id, // Also update the formValues
    }));
  };

   // handleChange for Meta Image
   const onChangeMetaImage = (newlySelectedImage) => {
    // setFeaturedImage(newlySelectedImage.image_id)
    setMetaImage(newlySelectedImage);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      meta_image: newlySelectedImage?.image_id, // Also update the formValues
    }));
  };

  const handleCheckboxChange = async (e) => {
    setIsFeatured(e.target.checked);
  };

  // Prepare the geo_location object conditionally
  const geoLocation =
    latitude && longitude
      ? {
          latitude: parseFloat(latitude).toFixed(5),
          longitude: parseFloat(longitude).toFixed(5),
        }
      : shopInfo?.geo_location; // Use existing values if not updated

  console.log("formValues", shopInfo);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const payload = {
      shop_id: id,
      ...formValues,
      description: description,
      area_id: user?.area_id,
      shop_timings: {
        working_hours: workingTime,
      },
      tags: {
        added_tags: addedTags,
        removed_tags: removedTags,
      },
      meta_image:metaImage?.image_id,
      // featured_image: featuredImage?.image_id || null,
      images: {
        images:
          selectedImages.length > 0
            ? selectedImages.filter((img) => img).map((img) => img.image_id)
            : null,
      },
      phone: formValues.phone || phoneNumber,
      status: status,
      is_featured: isFeatured,
      geo_location: geoLocation,
    };

    console.log(payload);

    try {
      const shopEditResponse = await callApi(
        `${baseUrl}/modules/shops/update`,
        "POST",
        headers,
        payload
      );
      console.log("res", shopEditResponse);
      if (shopEditResponse.success) {
        console.log("success", shopEditResponse);
        Toast.fire({
          icon: "success",
          title: "Shop edited successfully",
        });
        navigate("/shops");
      } else {
        setErrors(shopEditResponse.errors);
        console.error("Error: Response indicates failure", shopEditResponse);
        Toast.fire({
          icon: "error",
          title: shopEditResponse.message || "Failed to edit shop",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        icon: "error",
        title: "An error occurred while editing the shop",
      });
    }
  };

  // handleStatus approve

  const handleStatusApprove = async () => {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      shop_id: id,
      status: "STS_LIVE",
      action: "APPROVE",
    };

    console.log("payload =>", payload);

    try {
      const approveStatusResponse = await callApi(
        `${baseUrl}/modules/shops/update`,
        "POST",
        headers,
        payload
      );

      if (approveStatusResponse.success) {
        console.log("Status update success", approveStatusResponse);
        Toast.fire({
          title: "Approved",
          icon: "success",
        });
        navigate("/shops");
      } else {
        console.log("Status update failed", approveStatusResponse);
        Toast.fire({
          title: "Failed to Approve",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        title: "An error occurred while approving the shop",
        icon: "error",
      });
    }
  };

  // handleStatus reject

  const handleRejectChange = (e) => {
    setRejectReason({ ...rejectReason, [e.target.name]: e.target.value });
  };

  const handleStatusReject = async () => {
    console.log("inisde api status", status);
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      shop_id: id,
      reject_reason: rejectReason.reject_reason,
      status: "STS_REJECTED",
      action: "REJECT",
    };
    console.log("payload status", payload);

    try {
      const rejectStatusResponse = await callApi(
        `${baseUrl}/modules/shops/update`,
        "POST",
        headers,
        payload
      );

      if (rejectStatusResponse.success) {
        console.log("Status update success", rejectStatusResponse);
        Toast.fire({
          title: "Rejected",
          icon: "success",
        });
        navigate("/shops");
      } else {
        console.log("Status update failed", rejectStatusResponse);
        Toast.fire({
          title: "Failed to Reject",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        title: "An error occurred while rejecting the shop",
        icon: "error",
      });
    }
  };

  // handleStatus back to pending page

  const handleBackToPending = async () => {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      shop_id: id,
      status: "STS_PENDING",
      action: "APPROVE",
      phone: formValues.phone || phoneNumber,
    };
    console.log("your payload", payload);
    try {
      const backToPendingResponse = await callApi(
        `${baseUrl}/modules/shops/update`,
        "POST",
        headers,
        payload
      );

      if (backToPendingResponse.success) {
        console.log("backToPendingResponse", backToPendingResponse);
        Toast.fire({
          title: "Move to pending page",
          icon: "success",
        });
        navigate("/shops");
      } else {
        Toast.fire({
          title: "Failed to move pending page",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div className="page-heading">
        <div className="page-title">
          <div className="d-flex justify-content-between align-items-center row">
            <div className="col-md-6">
              <h3>Edit Shop</h3>
            </div>
            <div className="d-flex justify-content-end align-items-center col-md-6">
              {status === "STS_LIVE" ? (
                <div>
                  <div className="form-check">
                    <div className="custom-checkbox custom-control">
                      <input
                        type="checkbox"
                        className="form-check-danger form-check-input"
                        name="customCheck"
                        id="customColorCheck1"
                        onChange={handleCheckboxChange}
                        checked={isFeatured}
                      ></input>
                      <label
                        className="form-check-label"
                        for="customColorCheck1"
                      >
                        Is Featured
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}

              <div>
                {status === "STS_PENDING" ? (
                  <>
                    <button
                      className="mb-1 btn btn-success me-1"
                      onClick={() => handleStatusApprove()}
                    >
                      Approve
                    </button>

                    <button
                      type="button"
                      className="mb-1 btn btn-danger me-1"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      Reject
                    </button>

                    {/* <!-- Modal for Reject Button --> */}
                    <div
                      className="fade modal"
                      id="staticBackdrop"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Reason for Rejection
                            </h5>
                          </div>
                          <div className="modal-body">
                            <textarea
                              className="form-control"
                              id="reject_reason"
                              rows="3"
                              name="reject_reason"
                              value={rejectReason.reject_reason || ""}
                              onChange={handleRejectChange}
                              required
                            />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                              onClick={() => handleStatusReject()}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {/* <!--Button to move rejected services to the pending page --> */}

                {status === "STS_REJECTED" && (
                  <>
                    <button
                      className="mb-1 btn btn-success me-1"
                      onClick={() => handleBackToPending()}
                    >
                      Move to pending
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section">
        <section id="basic-horizontal-layouts">
          <div className="match-height row">
            <div className="col-lg-10 col-md-10">
              <div className="card">
                <div className="d-flex justify-content-between align-items-center card-header">
                  <h4 className="mb-0 card-title">Basic Information</h4>
                  <div className="">
                    <button
                      className="mb-1 btn btn-primary me-1"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                  {/* <div>{renderButton()}</div> */}
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form
                      className="form-horizontal form"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-body">
                        <div className="row">
                          <div className="col-md-4">
                            <label htmlFor="name">Title</label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder="Enter the title / name of your shop"
                              value={
                                formValues?.name !== undefined
                                  ? formValues?.name
                                  : shopInfo.name || ""
                              }
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Choose Shop Category
                            </label>
                          </div>
                          <div className="col-md-8">
                            <ShopCategorySelector
                              selectedCategory={
                                formValues?.category_id ||
                                shopInfo?.category_id ||
                                ""
                              }
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Add Tags
                            </label>
                          </div>

                          <div className="col-md-8 form-group">
                            <AddTags
                              setFormValues={setFormValues}
                              onAddTag={handleAddTag}
                              addedTags={addedTags}
                              setAddedTags={setAddedTags}
                              removedTags={removedTags}
                              setRemovedTags={setRemovedTags}
                            />
                            <div className="input-tag">
                              <ul className="input-tag__tags">
                                {shopInfo?.tags && shopInfo?.tags.length > 0
                                  ? shopInfo?.tags.map((tag, index) => (
                                      <li key={index}>
                                        {tag.name}
                                        <button
                                          type="button"
                                          onClick={() => deleteTag(tag)}
                                        >
                                          <i className="bi bi-x"></i>
                                        </button>
                                      </li>
                                    ))
                                  : null}
                              </ul>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="description">Description</label>
                          </div>
                          <div className="col-md-8 form-group">
                            <TextEditor
                              productDescription={shopInfo?.description}
                              onChangeTextEditorValue={onChangeTextEditorValue}
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="phone">Phone Number</label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="phone"
                              name="phone"
                              className="form-control"
                              value={
                                formValues?.phone !== undefined
                                  ? formValues.phone
                                  : shopInfo.phone
                              }
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="email">Email</label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              name="email"
                              id="email"
                              className="form-control"
                              value={
                                formValues?.email !== undefined
                                  ? formValues?.email
                                  : shopInfo?.email
                              }
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="basic-horizontal-layouts">
          <div className="match-height row">
            <div className="col-lg-10 col-md-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Working Hours</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form-horizontal form">
                      <div className="form-body">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-xs-12 form-group">
                            <WorkingHours
                              workingHours={workingHours}
                              setWorkingHours={setWorkingHours}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="basic-horizontal-layouts">
          <div className="match-height row">
            <div className="col-lg-10 col-md-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Listing Location</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form
                      className="form-horizontal form"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-body">
                        <div className="row">
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">Area</label>
                          </div>
                          <div className="col-md-8">
                            <AreaSelector
                              selectedArea={
                                formValues.area || shopInfo.area || ""
                              }
                              onChange={handleChange}
                              errors={errors}
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">State</label>
                          </div>
                          <div className="col-md-8">
                            <StateSelector
                              selectedState={
                                formValues?.address.state ||
                                shopInfo?.state ||
                                ""
                              }
                              onChange={handleAddressChange}
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">City</label>
                          </div>
                          <div className="col-md-8">
                            <CitySelector
                              selectedCity={
                                formValues.address.city || shopInfo.city || ""
                              }
                              onChange={handleAddressChange}
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="zip">PIN Code</label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="zip"
                              name="zip"
                              className="form-control"
                              placeholder="Select PIN code"
                              value={
                                formValues?.address.zip !== undefined
                                  ? formValues?.address.zip
                                  : shopInfo.zip
                              }
                              onChange={handleAddressChange}
                              required
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="line_1">Street</label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="line_1"
                              name="line_1"
                              className="form-control"
                              placeholder="Enter the address of your shop"
                              value={
                                formValues?.address.line_1 !== undefined
                                  ? formValues.address.line_1
                                  : shopInfo.line_1
                              }
                              onChange={handleAddressChange}
                              required
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="line_2">Nearby Landmark</label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              name="line_2"
                              id="line_2"
                              className="form-control"
                              placeholder="Enter landmarks near your your shop"
                              value={
                                formValues?.address.line_2 !== undefined
                                  ? formValues.address.line_2
                                  : shopInfo.line_2
                              }
                              onChange={handleAddressChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="row">
            <div className="col-lg-10 col-md-10">
              <div className="card">
                <Coordinates
                  coordinates={coordinates}
                  latitude={latitude}
                  longitude={longitude}
                  showLatitude={showLatitude}
                  showLongitude={showLongitude}
                  setCoordinates={setCoordinates}
                />

                <MapComponent
                  setLatitude={setLatitude}
                  setLongitude={setLongitude}
                  showLatitude={showLatitude}
                  showLongitude={showLongitude}
                  setShowLatitude={setShowLatitude}
                  setShowLongitude={setShowLongitude}
                />
              </div>
            </div>
          </div>
        </section>

        <section id="basic-horizontal-layouts">
          <div className="match-height row">
            <div className="col-lg-10 col-md-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Upload Images</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form
                      className="form-horizontal form"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-body">
                        <div className="row">
                          <div className="col-md-4">
                            <label htmlFor="coverImage">
                              Upload Cover Image
                            </label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                            <fieldset>
                              <SingleImageSelector
                                imageToShow={featuredImage}
                                onChangeImage={onChangeFeaturedImage}
                                setFormValues={setFormValues}
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Upload Other Images
                            </label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                            <fieldset>
                              <MultiImageSelector
                                selectedImages={selectedImages}
                                setSelectedImages={setSelectedImages}
                              />
                            </fieldset>
                          </div>

                          {/* <div className="d-flex justify-content-end col-sm-12">
                            <button
                              className="mb-1 btn btn-primary me-1"
                              onClick={handleSubmit}
                            >
                              Save
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="basic-horizontal-layouts">
          <div className="match-height row">
            <div className="col-lg-10 col-md-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">SEO Information</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form-horizontal form">
                      <div className="form-body">
                        <div className="row">
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">Meta Title</label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                            <input
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="meta_title"
                              value={formValues?.meta_title??shopInfo?.meta_title}
                              onChange={handleChange}
                            />
                            <div>Max length : 60</div>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Meta Description
                            </label>
                          </div>

                          <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                            <textarea
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="meta_description"
                              rows={4}
                              value={formValues?.meta_description??shopInfo?.meta_description}
                              onChange={handleChange}
                            />
                             <div className="d-md-flex justify-content-between">
                             <div>Character length : {formValues?.meta_description?.length??shopInfo?.meta_description?.length??0}</div>
                             <div>Max length : 160</div>
                             </div>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Upload Meta Image
                            </label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                            <fieldset>
                              <SingleImageSelector
                                imageToShow={metaImage}
                                onChangeImage={onChangeMetaImage}
                              />
                            </fieldset>
                          </div>
                          <div className="d-flex justify-content-end col-sm-12">
                            <button
                              className="mb-1 btn btn-primary me-1"
                              onClick={handleSubmit}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default EditShop;
