export const defaultLocation = [
    {
        "area_id": 2,
        "name": "Peelamedu",
        "city_code": "CBE",
        "latitude":"11.0346",
        "longitude":"77.0156",
        "coordinates":"11.034833743404572, 77.01547105365884"
    },{
        "area_id": 1,
        "name": "Saravanampatti",
        "city_code": "CBE",
        "latitude":"11.0797",
        "longitude":"76.9997",
        "coordinates":"11.07928738980652, 77.00174756340279"
    }
]