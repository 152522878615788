import React, { useState, useEffect, useRef } from "react";
import logo from "../../../assets/logo/logo.png";
import { callApi } from "../../api/Api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Message from "../../errors/Message";
import Swal from "sweetalert2";

const ConfirmEmail = () => {
  const [userForm, setUserForm] = useState({});
  const [errors, setErrors] = useState([]);
  const [searchParams] = useSearchParams();
  const login_email = searchParams.get("login_email") || "";
  const email = searchParams.get("email") || "";
  const navigate = useNavigate();

  console.log("login_email", login_email);
  console.log("email", email);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const handleChange = (e) => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const payload = {
      email: email || login_email,
    };
    try {
      const response = await callApi(
        `${baseUrl}/modules/users/forgot-password`,
        "POST",
        headers,
        payload
      );
      if (response.success) {
        if (login_email) {
          navigate(`/otp-capture?login_email=${login_email}`);
        } else {
            navigate(`/admin-otp-capture?email=${email}`);
        }
        Toast.fire({
          icon: "success",
          title: "OTP sended successfully",
        });
        console.log("otp", response);
      } else {
        setErrors(response.errors);
      }
      console.log("response.errors", response.errors);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <div className="row">
        <div className="mt-5 mb-5 col-12 col-md-5 login_col">
          <div id="auth-left">
            <div className="auth-logo">
              <a href="/">
                <img src={logo} alt="Logo" className="hypso_logo" />
              </a>
            </div>
            <h2 className="mt-3 auth-title">Confirm your Email id</h2>
            {/* <form onSubmit={handleSubmit}> */}
            <form
              action="#"
              className="form-horizontal form"
              onSubmit={handleSubmit}
            >
              <div className="form-body">
                <div className="position-relative has-icon-left mb-3 form-group">
                  <input
                    type="text"
                    name="email"
                    className="form-control form-control-xl"
                    placeholder="Email"
                    onChange={handleChange}
                    value={email || login_email}
                  />
                  <div>
                    <Message
                      type={"error"}
                      errors={errors}
                      fieldName={"email"}
                    />
                    <Message
                      type={"error"}
                      errors={errors}
                      fieldName={"user"}
                    />
                  </div>

                  <div className="form-control-icon">
                    <i className="bi bi-person"></i>
                  </div>
                </div>

                <div className="mb-2">
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"otp_code"}
                  />
                </div>
              </div>
              <button
                className="btn-block shadow-lg mt-2 btn btn-lg btn-primary"
                type="submit"
              >
                Confirm
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmEmail;
