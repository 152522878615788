import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { callApi } from "../../../api/Api";
import Swal from "sweetalert2";
import AddTags from "../../../Tags/AddTags/Addtags";
import "../../../Tags/EditTags/EditTags.css";
import SingleImageSelector from "../../../singleImageSelector/SingleImageSelector";
import { useUser } from "../../../../utils/utils";
import Message from "../../../errors/Message";
import ListSelector from "../../../selector/ListTypeSelector";
import MultiImageSelector from "../../../multiImageSelector/MultiImageSelector";
import TextEditor from "../../../richtext/TextEditor";


function EditOffer() {
    const { id } = useParams();
    const navigate = useNavigate();
    const inputFile = useRef(null);
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const [status, setStatus] = useState("");
    const [errors, setErrors] = useState([]);
    const [formValues, setFormValues] = useState({ address: {} });
    const [offerRecord, setOfferRecord] = useState({});
    const [phoneNumber, setPhoneNumber] = useState();
    const [userInfo, setUserInfo] = useState({});
    const { user, setUser } = useUser();
    const [addedTags, setAddedTags] = useState([]);
    const [removedTags, setRemovedTags] = useState([]);
    const [featuredImage, setFeaturedImage] = useState();
    const [coverImage, setCoverImage] = useState([]);
    const [rejectReason, setRejectReason] = useState({});
    const [isFeatured, setIsFeatured] = useState();
    const [isEditMode, setIsEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImages, setSelectedImages] = useState([])
    const [addOtherOffers, setAddOtherOffers] = useState({
        offer_name: '',
        mrp: '',
        offer_price: '',
        hypso_price: '',
        image_id: "",
        image_path: ''
    })

    const [showOtherOptions, setShowOtherOptions] = useState([])
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [recordTitle, setRecordTitle] = useState([])

    const [getIdFromListings, setIdFromListings] = useState()
    const [listingsRecord, setListingsRecord] = useState([])
    const [isChecked, setIsChecked] = useState(false)
    const [isCheckedOptionValue, setIsCheckedOptionValue] = useState(false)
    const [mainOffer, setMainOffer] = useState(true)
    const [percentageValue, setPercentageValue] = useState()
    const [percentageOptionsValue, setPercentageOptionsValue] = useState()
    const [description, setDescription] = useState("")

    const handlechangeforoptions = (e) => {
        setAddOtherOffers({ ...addOtherOffers, [e.target.name]: e.target.value })
    }


    useEffect(() => {
        percenatageCalculations(percentageValue, formValues?.mrp, mainOffer)
    }, [formValues?.mrp, percentageValue])

    useEffect(() => {
        console.log('working');

        percenatageCalculations(percentageOptionsValue, addOtherOffers?.mrp, !mainOffer)
    }, [addOtherOffers?.mrp, percentageOptionsValue])

    const percenatageCalculations = (percentageValue, mrp, mainOffer) => {
        if (mainOffer) {
            if (percentageValue && mrp) {
                var percentage = Math.round((mrp - (percentageValue / 100) * mrp))
                console.log("percentage", percentage);
                setFormValues(prevState => ({
                    ...prevState,
                    offer_price: percentage
                }))
            }
            // else {
            //     setFormValues(prevState => ({
            //         ...prevState,
            //         offer_price: null
            //     }))
            // }
        }
        else if (!mainOffer) {
            if (percentageValue && mrp) {
                var percentage = Math.round((mrp - (percentageValue / 100) * mrp))
                console.log("percentage", percentage);
                setAddOtherOffers(prevState => ({
                    ...prevState,
                    offer_price: percentage
                }))
            }
            // else {
            //     setAddOtherOffers(prevState => ({
            //         ...prevState,
            //         offer_price: null
            //     }))
            // }
        }
    }

    const handleoptionsubmit = (e) => {
        e.preventDefault()
        if (isEditing) {
            // Update the existing offer in showOtherOptions
            setShowOtherOptions((prevOffers) =>
                prevOffers.map((offer) =>
                    offer.offer_name === editId ? { ...addOtherOffers, offer_name: editId } : offer
                )
            );
            setIsEditing(false); // Exit editing mode
            setEditId(null); // Clear the editId
        } else {
            // Add a new offer
            setShowOtherOptions((prevOffers) => [...prevOffers, addOtherOffers]);
        }

        // Reset form with incremented ID
        setAddOtherOffers({
            offer_name: '',
            mrp: '',
            offer_price: '',
            hypso_price: '',
            image_id: '',
            image_path: ''
        });
        inputFile.current.value = "";
    }

    const handleDeleteOffers = (offer_name) => {
        console.log("offer_id", offer_name);
        const updateShowOtherOffers = showOtherOptions.filter((showOtherOption) => showOtherOption.offer_name != offer_name)
        setShowOtherOptions(updateShowOtherOffers)
        // Reset form with incremented ID
        setAddOtherOffers({
            offer_name: '',
            mrp: '',
            offer_price: '',
            hypso_price: '',
            image_id: '',
            image_path: '',
        });
    }


    const handleEditOffers = (offer) => {
        setAddOtherOffers(offer); // Fill form with the offer being edited
        setIsEditing(true); // Enter editing mode
        setEditId(offer.offer_name); // Track the id of the offer being edited
    };

    const onchangeOptionImage = (id, path) => {
        console.log('optionImage', id);
        console.log('path', path);
        setAddOtherOffers(prevState => ({
            ...prevState,
            image_id: id,
            image_path: path
        }));
    };

    // Image upload for other option 
    const handleImageSelect = async (event) => {
        const imageToUpload = event.target.files[0];
        //upload image using api and receive image object
        if (imageToUpload) {
            await uploadImage(imageToUpload);
        }
    }

    const uploadImage = async (imageToUpload) => {
        //turn on loader
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("file", imageToUpload);
            formData.append('area_id', user?.area_id);

            const headers = {
                token: user?.token,
            };

            var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

            const featureImageResponse = await fetch(
                `${baseUrl}/modules/images/upload`,
                {
                    method: "POST",
                    headers: headers,
                    body: formData,
                }
            );

            if (featureImageResponse.ok) {
                const uploadedImage = await featureImageResponse.json();
                console.log("Upload Successful:" + JSON.stringify(uploadedImage));
                onchangeOptionImage(uploadedImage.image_id, uploadedImage.path);
                //call onChangeImage and pass uploaded image object
            } else {
                console.error("Upload Failed:", featureImageResponse.statusText);
                // Handle error
            }
        } catch (error) {
            console.error("Upload Failed:", error);
            // Handle error
        } finally {
            //turn on loader
            setIsLoading(false);
        }
    };

    useEffect(() => {
        console.log(formValues?.entity_type);
        handleRecords()

    }, [formValues?.entity_type])

    const handleRecords = async () => {
        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };
        var payload = {
            area_id: user?.area_id,
            filters: {
                conditions: [
                    {
                        field: "entity_type",
                        operator: "equals",
                        value: formValues?.entity_type,
                        dataType: "string",
                    },
                ],
            },
        };

        if (formValues?.entity_type === "ENT_SHOP") {
            const shopResponse = await callApi(
                `${baseUrl}/modules/shops/get-Shops`,
                "POST",
                headers,
                payload
            );
            setRecordTitle(
                shopResponse?.records.map((record) => record)
            )
            // console.log("shopResponse", shopResponse);
        }

        else if (formValues?.entity_type === "ENT_SERVICE") {

            const serviceResponse = await callApi(
                `${baseUrl}/modules/services/get-services`,
                "POST",
                headers,
                payload
            );

            setRecordTitle(
                serviceResponse?.records.map((record) => record)
            )
            // console.log("serviceResponse", serviceResponse);
        }
    };

    //Toast for EditShop
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        },
    });


    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    useEffect(() => {
        console.log("id", id);

        async function fetchOfferData() {
            var headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: user?.token,
            };
            try {
                const offerData = await callApi(
                    `${baseUrl}/modules/offers/get-offer/${id}`,
                    "POST",
                    headers
                );
                console.log("response", offerData);

                if (!offerData || !offerData?.record) {
                    navigate('/*')
                    console.log("no");

                }
                setOfferRecord(offerData?.record);
                setPhoneNumber(offerData?.record?.phone);
                setFeaturedImage(offerData?.record?.featured_image);
                setSelectedImages(Array.isArray(offerData?.record?.images) ? offerData.record.images : []);
                setStatus(offerData?.record?.offer_status);
                setFormValues(prevState => ({
                    ...prevState,
                    entity_type: offerData?.record?.entity_type
                }))
                setIsFeatured(offerData?.record?.is_featured);
                setShowOtherOptions(offerData?.record?.other_offers?.map(other_offer => other_offer))

            } catch (error) {
                console.error("There was an error fetching the data!", error);
            }
        }

        fetchOfferData();
    }, [id]);

    console.log("selectedimages", selectedImages);

    const deleteTag = (deleteTag) => {
        // Find the index of the tag to be removed
        const updatedTagIndex = offerRecord.tags.findIndex(
            (tag) => tag.tag_id === deleteTag.tag_id
        );

        // If the tag is found, remove it
        if (updatedTagIndex !== -1) {
            // Create a new array without the removed tag
            const newTags = [
                ...offerRecord.tags.slice(0, updatedTagIndex),
                ...offerRecord.tags.slice(updatedTagIndex + 1),
            ];
            // Update the formValues state with the modified array
            setOfferRecord({ ...offerRecord, tags: newTags });
            // Add the removed tag to the removedTags state
            setRemovedTags((prevRemovedTags) => [...prevRemovedTags, deleteTag]);
        }
    };

    const handleAddTag = (tag) => {
        //adds a tag to the tags array in the formValues state.
        setOfferRecord((prevFormValues) => ({
            ...prevFormValues,
            tags: [...prevFormValues.tags, tag],
        }));
        //updates the addedTags state
        setAddedTags((prevAddedTags) => [...prevAddedTags, tag]);
    };

    const onChangeTextEditorValue = (value) => {
        setDescription(value)
      }

      const onChangeTermsValue = (value) => {
        setFormValues(prevFormValues => ({
            ...prevFormValues,
            terms_and_conditions: value // Also update the formValues
        }));
    }
    const onChangeAdditionalValue = (value) => {
        setFormValues(prevFormValues => ({
            ...prevFormValues,
            additional_info: value // Also update the formValues
        }));
    }

    const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
        const { name, value } = e.target;
        // If entity_type changes, reset entity_details_id
        if (name === "entity_type") {
            setFormValues({
                ...formValues,
                [name]: value,
                entity_details_id: "", // Reset entity_details_id
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    // get the list id based on the their entity id
    useEffect(() => {
        const selectedRecord = recordTitle.find(
            (record) => record.entity_details_id == formValues?.entity_details_id
        );
        setIdFromListings(selectedRecord ? selectedRecord?.service_id || selectedRecord?.shop_id : null)

    }, [formValues?.entity_details_id])

    useEffect(() => {
        getListingRecord()
    }, [getIdFromListings])

    // get the list records
    const getListingRecord = async () => {
        // e.preventDefault()   ;

        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };
        if (formValues?.entity_type === "ENT_SHOP") {
            const shop_record = await callApi(
                `${baseUrl}/modules/shops/get-shop/${getIdFromListings}`,
                "POST",
                headers,
            );
            setListingsRecord(shop_record?.record)
        }
        else if (formValues?.entity_type === "ENT_SERVICE") {
            const service_record = await callApi(
                `${baseUrl}/modules/services/get-service/${getIdFromListings}`,
                "POST",
                headers,
            );
            setListingsRecord(service_record?.record)
        }
    }



    // handleChange for featuredImages
    const onChangeFeaturedImage = (newlySelectedImage) => {
        setFeaturedImage(newlySelectedImage);
        setFormValues(prevFormValues => ({
            ...prevFormValues,
            featured_image: newlySelectedImage?.image_id // Also update the formValues
        }));

    };

    const handleCheckboxChange = async (e) => {
        setIsFeatured(e.target.checked);
    };

    // Prepare the geo_location object conditionally

    const updateOffer = async (e) => {
        e.preventDefault();

        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };
        var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

        const payload = {
            offer_id: id,
            ...formValues,
            description:description,
            area_id: user?.area_id,
            tags: {
                added_tags: addedTags,
                removed_tags: removedTags,
            },
            images: {
                images:
                    selectedImages && selectedImages.length > 0
                        ? selectedImages.filter((img) => img).map((img) => img.image_id)
                        : null,
            },
            featured_image: featuredImage.image_id,
            offer_status: status,
            is_featured: isFeatured,
        };
        console.log("edit_payload", payload);

        try {
            const offerEditResponse = await callApi(
                `${baseUrl}/modules/offers/update`,
                "POST",
                headers,
                payload
            );
            console.log("response", offerEditResponse);

            if (offerEditResponse.success) {
                console.log("success", offerEditResponse);
                Toast.fire({
                    icon: "success",
                    title: "Offer edited successfully",
                });
                navigate("/offers");
            } else {
                setErrors(offerEditResponse.errors);
                console.error("Error: Response indicates failure", offerEditResponse);
                Toast.fire({
                    icon: "error",
                    title: offerEditResponse.message || "Failed to edit offer",
                });
            }
        } catch (error) {
            console.error("Error:", error);
            Toast.fire({
                icon: "error",
                title: "An error occurred while editing the offer",
            });
        }
    };

    // handleStatus approve

    const handleStatusApprove = async () => {
        const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
        const headers = {
            "Content-Type": "application/json",
            token: user?.token,
        };
        const payload = {
            offer_id: id,
            offer_status: "STS_LIVE",
            action: "APPROVE",
           
        };

        console.log("payload =>", payload);

        try {
            const approveStatusResponse = await callApi(
                `${baseUrl}/modules/offers/update`,
                "POST",
                headers,
                payload
            );

            if (approveStatusResponse.success) {
                console.log("Status update success", approveStatusResponse);
                Toast.fire({
                    title: "Approved",
                    icon: "success",
                });
                navigate("/offers");
            } else {
                console.log("Status update failed", approveStatusResponse);
                Toast.fire({
                    title: "Failed to Approve",
                    icon: "error",
                });
            }
        } catch (error) {
            console.error("Error:", error);
            Toast.fire({
                title: "An error occurred while approving the shop",
                icon: "error",
            });
        }
    };

    // handleStatus reject

    const handleRejectChange = (e) => {
        setRejectReason({ ...rejectReason, [e.target.name]: e.target.value });
    };

    const handleStatusReject = async () => {
        const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
        const headers = {
            "Content-Type": "application/json",
            token: user?.token,
        };
        const payload = {
            offer_id: id,
            reject_reason: rejectReason.reject_reason,
            offer_status: "STS_REJECTED",
            action: "REJECT",
          
        };
        console.log("payload status", payload);

        try {
            const rejectStatusResponse = await callApi(
                `${baseUrl}/modules/offers/update`,
                "POST",
                headers,
                payload
            );

            if (rejectStatusResponse.success) {
                console.log("Status update success", rejectStatusResponse);
                Toast.fire({
                    title: "Rejected",
                    icon: "success",
                });
                navigate("/offers");
            } else {
                console.log("Status update failed", rejectStatusResponse);
                Toast.fire({
                    title: "Failed to Reject",
                    icon: "error",
                });
            }
        } catch (error) {
            console.error("Error:", error);
            Toast.fire({
                title: "An error occurred while rejecting the offer",
                icon: "error",
            });
        }
    };

    // handleStatus back to pending page

    const handleBackToPending = async () => {
        const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
        const headers = {
            "Content-Type": "application/json",
            token: user?.token,
        };
        const payload = {
            offer_id: id,
            offer_status: "STS_PENDING",
            action: "APPROVE",
        };
        console.log("your payload", payload);
        try {
            const backToPendingResponse = await callApi(
                `${baseUrl}/modules/offers/update`,
                "POST",
                headers,
                payload
            );

            if (backToPendingResponse.success) {
                console.log("backToPendingResponse", backToPendingResponse);
                Toast.fire({
                    title: "Move to pending page",
                    icon: "success",
                });
                navigate("/offers");
            } else {
                Toast.fire({
                    title: "Failed to move pending page",
                    icon: "error",
                });
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div>
            <div className="page-heading">
                <div className="page-title">
                    <div className="d-flex justify-content-between align-items-center row">
                        <div className="col-md-6">
                            <h3>Edit Offer</h3>
                        </div>
                        <div className="d-flex justify-content-end align-items-center col-md-6">
                            {status === "STS_LIVE" ? (
                                <div>
                                    <div className="form-check">
                                        <div className="custom-checkbox custom-control">
                                            <input
                                                type="checkbox"
                                                className="form-check-danger form-check-input"
                                                name="customCheck"
                                                id="customColorCheck1"
                                                onChange={handleCheckboxChange}
                                                checked={isFeatured}
                                            ></input>
                                            <label className="form-check-label" htmlFor="customColorCheck1">
                                                Is Featured
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <div>
                                {status === "STS_PENDING" ? (
                                    <>
                                        <button
                                            className="mb-1 btn btn-success me-1"
                                            onClick={() => handleStatusApprove()}
                                        >
                                            Approve
                                        </button>

                                        <button
                                            type="button"
                                            className="mb-1 btn btn-danger me-1"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop"
                                        >
                                            Reject
                                        </button>

                                        {/* <!-- Modal for Reject Button --> */}
                                        <div
                                            className="fade modal"
                                            id="staticBackdrop"
                                            data-bs-backdrop="static"
                                            data-bs-keyboard="false"
                                            aria-labelledby="staticBackdropLabel"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5
                                                            className="modal-title"
                                                            id="staticBackdropLabel"
                                                        >
                                                            Reason for Rejection
                                                        </h5>
                                                    </div>
                                                    <div className="modal-body">
                                                        <textarea
                                                            className="form-control"
                                                            id="reject_reason"
                                                            rows="3"
                                                            name="reject_reason"
                                                            value={rejectReason.reject_reason || ""}
                                                            onChange={handleRejectChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            data-bs-dismiss="modal"
                                                        >
                                                            Close
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            data-bs-dismiss="modal"
                                                            onClick={() => handleStatusReject()}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}

                                {/* <!--Button to move rejected services to the pending page --> */}

                                {status === "STS_REJECTED" && (
                                    <>
                                        <button
                                            className="mb-1 btn btn-success me-1"
                                            onClick={() => handleBackToPending()}
                                        >
                                            Move to pending
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="active nav-link" id="basicInformation-tab" data-bs-toggle="tab"
                                            href="#basicInformation" role="tab" aria-controls="basicInformation"
                                            aria-selected="true">Basic Information</a>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="duration-tab" data-bs-toggle="tab" href="#duration" role="tab"
                                            aria-controls="duration" aria-selected="false">Duration</a>
                                    </li>
                                </ul>

                                <div className="tab-content" id="myTabContent">
                                    <div className="active fade show tab-pane" id="basicInformation" role="tabpanel"
                                        aria-labelledby="basicInformation-tab">
                                        {/* Basic Information Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="match-height row">
                                                <div className="col-lg-11 col-md-10">
                                                    <div className="mb-0 card">
                                                        <div className="mt-4 p-0 card-header">
                                                            <h4 className="card-title">Basic Information</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form-horizontal form">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Listing type
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <ListSelector
                                                                                    selectedList={formValues?.entity_type || offerRecord?.entity_type}
                                                                                    onChange={handleChange}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Select Shop /
                                                                                    Service</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <fieldset className="form-group">
                                                                                    <select onChange={handleChange} className="form-select" value={formValues?.entity_details_id || offerRecord?.entity_details_id} name="entity_details_id" id="">
                                                                                        <option value="" disabled selected>
                                                                                            Select
                                                                                        </option>
                                                                                        {
                                                                                            recordTitle.map(record => (
                                                                                                <option value={record?.entity_details_id}>{record.name}</option>
                                                                                            ))
                                                                                        }
                                                                                    </select>

                                                                                </fieldset>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Title</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    value={formValues?.offer_name || offerRecord?.offer_name}
                                                                                    onChange={handleChange}
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="offer_name"
                                                                                    placeholder="Enter the title of your offer"
                                                                                    required></input>
                                                                                <div>
                                                                                    <Message type={"error"} errors={errors}
                                                                                        fieldName={"offer_name"} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Product
                                                                                    Name</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    value={formValues?.product_name || offerRecord?.product_name}
                                                                                    onChange={handleChange}
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="product_name"
                                                                                    placeholder="Enter the product name of your offer"
                                                                                    required></input>
                                                                                <div>
                                                                                    <Message type={"error"} errors={errors}
                                                                                        fieldName={"product_name"} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Actual Price</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    value={formValues?.mrp || offerRecord?.mrp}
                                                                                    onChange={handleChange}
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="mrp"
                                                                                    placeholder="Enter the total price"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"mrp"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="pt-2 pb-3 col-md-4">
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" onChange={e => setIsChecked(e.target.checked)} value={isChecked} type="checkbox" id="flexSwitchCheckDefault" />
                                                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Percentage Calculations</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                {
                                                                                    isChecked && (
                                                                                        <input
                                                                                            type="text"
                                                                                            value={percentageValue}
                                                                                            onChange={e => setPercentageValue(e.target.value)}
                                                                                            id="first-name-horizontal"
                                                                                            className="form-control"
                                                                                            name="percentage"
                                                                                            placeholder="Enter the percentage"
                                                                                            required
                                                                                        ></input>
                                                                                    )
                                                                                }
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Offer price</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    value={formValues?.offer_price || offerRecord?.offer_price}
                                                                                    onChange={handleChange}
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="offer_price"
                                                                                    placeholder="Enter the offer price"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"offer_price"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Hypso offer price</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="hypso_price"
                                                                                    value={formValues?.hypso_price || offerRecord?.hypso_price}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the Hypso offer price"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"hypso_price"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Offer Count</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="offer_count"
                                                                                    value={formValues?.offer_count || offerRecord?.offer_count}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the offer count"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"offer_count"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Description
                                                                                </label>
                                                                            </div>

                                                                            <div className="col-md-8 form-group">
                                                                            <TextEditor productDescription={offerRecord?.description} onChangeTextEditorValue={onChangeTextEditorValue} />
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Phone Number
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    value={formValues?.phone || offerRecord?.phone}
                                                                                    onChange={handleChange}
                                                                                    type="tel"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="phone"
                                                                                    required></input>
                                                                                <div>
                                                                                    <Message type={"error"} errors={errors}
                                                                                        fieldName={"phone"} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Email</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="email"
                                                                                    value={formValues?.email || offerRecord?.email}
                                                                                    onChange={handleChange}
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="email"
                                                                                    ></input>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Street</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    value={formValues?.line_1 || offerRecord?.line_1}
                                                                                    onChange={handleChange}
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="line_1"
                                                                                    required></input>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Nearby Landmark</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    value={formValues?.line_2 || offerRecord?.line_2}
                                                                                    onChange={handleChange}
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="line_2"
                                                                                    required></input>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Terms & Conditions
                                                                                </label>
                                                                            </div>

                                                                            <div className="col-md-8 form-group">
                                                                            <TextEditor productDescription={offerRecord?.terms_and_conditions} onChangeTextEditorValue={onChangeTermsValue} />
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Upload Featured Image
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                                                                                <fieldset>
                                                                                    <SingleImageSelector
                                                                                        setFormValues={setFormValues}
                                                                                        imageToShow={featuredImage}
                                                                                        onChangeImage={onChangeFeaturedImage} />
                                                                                </fieldset>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Upload Gallery Image
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                                                                                <fieldset>
                                                                                    <MultiImageSelector
                                                                                        selectedImages={selectedImages}
                                                                                        setSelectedImages={setSelectedImages}
                                                                                    />
                                                                                </fieldset>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Add Tags
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <AddTags
                                                                                    onAddTag={handleAddTag}
                                                                                    addedTags={addedTags}
                                                                                    setAddedTags={setAddedTags}
                                                                                    removedTags={removedTags}
                                                                                    setRemovedTags={setRemovedTags} />
                                                                                <div className="input-tag">
                                                                                    <ul className="input-tag__tags">
                                                                                        {offerRecord?.tags && offerRecord?.tags.length > 0
                                                                                            ? offerRecord?.tags.map((tag, index) => (
                                                                                                <li key={index}>
                                                                                                    {tag.name}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        onClick={() => deleteTag(tag)}
                                                                                                    >
                                                                                                        <i className="bi bi-x"></i>
                                                                                                    </button>
                                                                                                </li>
                                                                                            ))
                                                                                            : null}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="match-height row">
                                                <div className="d-flex justify-content-end col-lg-12 col-md-10">
                                                    <div className="mb-0 card">
                                                        <div className="card-body">
                                                            <button
                                                                onClick={updateOffer}
                                                                className="mb-1 btn btn-primary me-1"
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                    </div>


                                    <div className="fade tab-pane" id="duration" role="tabpanel" aria-labelledby="duration-tab">
                                        {/* timing and duration section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="match-height row">
                                                <div className="col-lg-10 col-md-10">
                                                    <div className="mb-0 card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Duration</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form-horizontal form">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Start Date</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="date"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="offer_start_date"
                                                                                    placeholder="Enter the offer start date"
                                                                                    required
                                                                                    value={formValues?.offer_start_date || offerRecord?.offer_start_date}
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"offer_start_date"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">End Date</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="date"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="offer_end_date"
                                                                                    placeholder="Enter the offer price"
                                                                                    required
                                                                                    value={formValues?.offer_end_date || offerRecord?.offer_end_date}
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"offer_end_date"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Offer Status</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <fieldset className="form-group">
                                                                                    <select
                                                                                        name="offer_status"
                                                                                        className="form-select"
                                                                                        value={formValues?.offer_status || offerRecord?.offer_status}
                                                                                        onChange={handleChange}
                                                                                    >
                                                                                        <option value="" disabled selected>
                                                                                            Select offer status
                                                                                        </option>
                                                                                        <option value="Active">Active</option>
                                                                                        <option value="Inactive">Inactive</option>
                                                                                    </select>
                                                                                    <div>

                                                                                    </div>
                                                                                </fieldset>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Additional Information
                                                                                </label>
                                                                            </div>

                                                                            <div className="col-md-8 form-group">
                                                                            <TextEditor productDescription={offerRecord?.additional_info} onChangeTextEditorValue={onChangeAdditionalValue} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section >
                                        <section id="basic-horizontal-layouts">
                                            <div className="match-height row">
                                                <div className="d-flex justify-content-end col-lg-10 col-md-10">
                                                    <div className="mb-0 card">
                                                        <div className="card-body">
                                                            <button
                                                                onClick={updateOffer}
                                                                className="mb-1 btn btn-primary me-1"
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </div>
    );
}

export default EditOffer;
