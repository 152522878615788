import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Message from "../../errors/Message";
import { callApi } from "../../api/Api";
import logo from "../../../assets/logo/logo.png";

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const emailFromQuery = searchParams.get("email") || "";
  const [userDetails, setUserDetails] = useState({ email: emailFromQuery });
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();  

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const payload = { ...userDetails };
    console.log("Payload:", payload);

    try {
      const response = await callApi(
        `${baseUrl}/modules/users/forgot-password`,
        "POST",
        headers,
        payload
      );

      if (response.success) {
        navigate(`/otp-capture?login_email=${userDetails?.email}`);
      } else {
        setErrors(response.errors || [{ message: "Request failed" }]);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrors([{ message: "An error occurred during the request." }]);
    }
  };

  return (
    <div className="row">
      <div className="mt-5 mb-5 col-12 col-md-5 login_col">
        <div id="auth-left" className="mt-4">
          <div className="auth-logo">
            <a href="/">
              <img src={logo} alt="Logo" className="hypso_logo" />
            </a>
          </div>

          <h1 className="mt-3 mb-3 auth-title">Find your account</h1>
          <p className="auth-subtitle">
            Enter your email address to receive a verification code.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="position-relative has-icon-left mb-4 form-group">
              <input
                type="email"
                name="email"
                className="form-control form-control-xl"
                placeholder="Enter your email"
                onChange={handleChange}
                value={userDetails.email}
                required
              />
              <div className="d-flex justify-content-between align-items-center mt-2">
                <Message type="error" errors={errors} fieldName="email" />
              </div>
              <div className="form-control-icon">
                <i className="bi bi-envelope"></i>
              </div>
            </div>

            <button
              className="btn-block shadow-lg btn btn-lg btn-primary"
              type="submit"
            >
              Send
            </button>
            <div className="mt-3 text-center text-lg fs-5">
              <p className="text-gray-600">
                Remember your account?{" "}
                <a href="/login" className="font-bold">
                  Log in
                </a>.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
