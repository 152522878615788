import React, { useState, useEffect } from "react";
import { callApi } from "../../api/Api";
import Swal from "sweetalert2";
import CitySelector from "../../selector/CitySelector";
import StateSelector from "../../selector/StateSelector";
import WorkingHours from "../../workingHours/WorkingHours";
import SingleImageSelector from "../../singleImageSelector/SingleImageSelector";
import MultiImageSelector from "../../multiImageSelector/MultiImageSelector";
import { useUser } from "../../../utils/utils";
import AreaSelector from "../../selector/AreaSelector";
import Message from "../../errors/Message";
import { useNavigate } from "react-router-dom";
import MapComponent from "../../mapSelector/MapComponent";
import { defaultLocation } from "../../../utils/locationUtils";
import AddTags from "../../Tags/AddTags/Addtags";
import AddListing from "../../createListing/AddListing";
import SearchableDropdown from "../../dropdown/SearchableDropdown";
import { areaMappings } from "../../../utils/areaUtils";
import TextEditor from "../../richtext/TextEditor";
import "./AddShop.css";
import Coordinates from "../../mapSelector/Coordinates";

function AddShop() {
  const [formValues, setFormValues] = useState({
    address: {},
  });
  const [addedTags, setAddedTags] = useState([]);
  const [removedTags, setRemovedTags] = useState([]);
  const { user, setUser } = useUser();
  const [errors, setErrors] = useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [metaImage, setMetaImage] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [phoneNumber, setPhoneNumber] = useState(formValues?.phone || "");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState("");
  const [showLatitude, setShowLatitude] = useState(
    defaultLocation[user?.area_id]?.latitude || 0
  );
  const [showLongitude, setShowLongitude] = useState(
    defaultLocation[user?.area_id]?.longitude || 0
  );
  const navigate = useNavigate();
  const [customerToken, setCustomerToken] = useState("");
  const [customerMobileNumber, setCustomerMobileNumber] = useState("");
  const [description, setDescription] = useState("");
  const [coordinates, setCoordinates] = useState("");

  //WorkingHours Data
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [workingHours, setWorkingHours] = useState(
    days.map((day) => ({
      day,
      start: {
        time: "",
        session: "",
      },
      end: {
        time: "",
        session: "",
      },
      status: false, //Initially, none of the states of active
    }))
  );
  const [workingTime, setWorkingTime] = useState([]);

  useEffect(() => {
    setWorkingTime(
      workingHours.map((day) =>
        day.status
          ? day // Keep the times as they are if status is true
          : {
            ...day,
            start: { time: "", session: "" },
            end: { time: "", session: "" },
          }
      )
    );
  }, [workingHours]);

  useEffect(() => {
    const area = formValues?.address?.area;

    if (areaMappings[area]) {
      setFormValues((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          ...areaMappings[area], // Dynamically assign city, state, and zip
        },
      }));
    }
  }, [formValues?.address?.area]);

  const handleAddressChange = (e) => {
    var updatedAddress = {
      ...formValues.address,
      [e.target.name]: e.target.value,
    };
    console.log("updated Address - " + JSON.stringify(updatedAddress));
    setFormValues({ ...formValues, ...{ address: updatedAddress } });
  };

  // Update phoneNumber whenever formValues.phone changes
  useEffect(() => {
    setPhoneNumber(formValues.phone || ""); // Keep phoneNumber in sync with formValues.phone
  }, [formValues.phone]);

  useEffect(() => {
    setFormValues({
      ...formValues,
      phone: customerMobileNumber,
    });
  }, [customerMobileNumber]);

  // useEffect for userDataFetch
  useEffect(() => {
    userDataFetch();
  }, []);

  useEffect(() => {
    if (coordinates) {
      const [lat, long] = coordinates.split(",").map((coord) => coord.trim());
      if (!isNaN(lat) && !isNaN(long)) {
        setLatitude(parseFloat(lat));
        setLongitude(parseFloat(long));
      } else {
        console.error(
          'Invalid coordinates format. Please use "latitude, longitude".'
        );
      }
    }
  }, [coordinates]);

  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  // handleChange for featuredImages
  const onChangeFeaturedImage = (newlySelectedImage) => {
    // setFeaturedImage(newlySelectedImage.image_id)
    setFeaturedImage(newlySelectedImage);
    if(!metaImage){
      setMetaImage(newlySelectedImage)
    }
  };

  // handleChange for Meta Image
  const onChangeMetaImage = (newlySelectedImage) => {
    // setFeaturedImage(newlySelectedImage.image_id)
    setMetaImage(newlySelectedImage);
  };

  // handle rich text editor html
  const onChangeTextEditorValue = (value) => {
    setDescription(value);

  };


  // Toast for Shop Add
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

  const userDataFetch = async () => {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    try {
      const userData = await callApi(
        `${baseUrl}/modules/users/get-user/${user?.user_id}`,
        "POST",
        headers
      );
      // console.log("user=>", userData);
      setUserInfo(userData.record);
    } catch (error) {
      console.log("errors", error);
    }
  };

  const handleShopSubmit = async (e) => {
    e.preventDefault();

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: customerToken ? customerToken : user?.token,
    };
    const image_id = selectedImages?.map((selectImage) => selectImage.image_id);

    var payload = {
      ...formValues,
      client: "ADMIN_CONSOLE",
      description: description,
      area_id: user?.area_id,
      shop_timings: {
        working_hours: workingTime,
      },
      meta_title: formValues.meta_title ?? formValues.name,
      meta_description: formValues.meta_description ?? convertRichTextToPlainText(description),
      meta_image: metaImage?.image_id,
      entity_type: "ENT_SHOP",
      featured_image: featuredImage.image_id,
      images: { images: image_id },
      geo_location: {
        latitude: latitude || null,
        longitude: longitude || null,
      },
    };

    console.log(payload);

    try {
      const ShopResponse = await callApi(
        `${baseUrl}/modules/shops/create`,
        "POST",
        headers,
        payload
      );
      console.log("response", ShopResponse);
      if (ShopResponse.success) {
        console.log("success", ShopResponse);
        Toast.fire({
          icon: "success",
          title: "Data saved successfully",
        });
        navigate("/shops");
      } else {
        setErrors(ShopResponse.errors);
        console.error("Error: Response indicates failure", ShopResponse);
        Toast.fire({
          icon: "error",
          title: ShopResponse.message || "Failed to save data",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        icon: "error",
        title: "An error occurred while adding the shop",
      });
    }
  };

  function convertRichTextToPlainText(richText) {
    // Create a temporary DOM element
    const tempElement = document.createElement('div');
    tempElement.innerHTML = richText;

    // Extract and return the plain text
    return (tempElement.textContent || tempElement.innerText || '').match(/.*?[.?!](\s|$)/)?.[0];
  }


  console.log("formValues", formValues);

  return (
    <div>
      <div className="page-heading">
        <div className="page-title">
          <div className="row">
            <div className="order-last order-md-1 pb-4 col-12 col-md-6">
              <h3>Add Shop</h3>
            </div>
            <div className="order-first order-md-2 col-12 col-md-6">
              <nav
                aria-label="breadcrumb"
                className="float-lg-end float-start breadcrumb-header"
              ></nav>
            </div>
          </div>
        </div>
      </div>
      <AddListing
        type={"shop"}
        setCustomerMobileNumber={setCustomerMobileNumber}
        customerToken={customerToken}
        setCustomerToken={setCustomerToken}
      />
      <section className="section">
        <section id="basic-horizontal-layouts">
          <div className="match-height row">
            <div className="col-lg-10 col-md-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Basic Information</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form-horizontal form">
                      <div className="form-body">
                        <div className="row">
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal mandatory">Title <span style={{ color: "red" }}> *</span></label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="first-name-horizontal"
                              className="form-control "
                              name="name"
                              value={formValues["name"] || ""}
                              onChange={handleChange}
                              placeholder="Enter the title / name of your shop"
                              required
                            ></input>
                            <div>
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"name"}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Choose Shop Category
                            </label>
                          </div>
                          <div className="mb-3 col-md-8">
                            <SearchableDropdown
                              setFormValues={setFormValues}
                              url={"by-entity"}
                              entity_type={"ENT_SHOP"}
                            />
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Add Tags
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <AddTags
                              name={"shop"}
                              setFormValues={setFormValues}
                              addedTags={addedTags}
                              setAddedTags={setAddedTags}
                              removedTags={removedTags}
                              setRemovedTags={setRemovedTags}
                            />
                            <Message
                              type={"error"}
                              errors={errors}
                              fieldName={"tag_id"}
                            />
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Description
                            </label>
                          </div>

                          <div className="col-md-8 form-group">
                            <TextEditor
                              onChangeTextEditorValue={onChangeTextEditorValue}
                            />
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Phone Number
                              <span style={{ color: "red" }}> *</span>
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="tel"
                              id="first-name-horizontal"
                              className="form-control"
                              name="phone"
                              value={formValues["phone"] || ""}
                              onChange={handleChange}
                              required
                            ></input>
                            <div>
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"phone"}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">Email </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="email"
                              id="first-name-horizontal"
                              className="form-control"
                              name="email"
                              value={formValues["email"] || ""}
                              onChange={handleChange}
                            ></input>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="basic-horizontal-layouts">
          <div className="match-height row">
            <div className="col-lg-10 col-md-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Working Hours</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form-horizontal form">
                      <div className="form-body">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-xs-12 form-group">
                            <WorkingHours
                              workingHours={workingHours}
                              setWorkingHours={setWorkingHours}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="basic-horizontal-layouts">
          <div className="match-height row">
            <div className="col-lg-10 col-md-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Listing Location</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form-horizontal form">
                      <div className="form-body">
                        <div className="row">
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">Area <span style={{ color: "red" }}> *</span></label>
                          </div>
                          <div className="col-md-8">
                            <AreaSelector
                              selectedArea={formValues?.area}
                              onChange={handleAddressChange}
                              errors={errors}
                            />
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">State</label>
                          </div>
                          <div className="col-md-8">
                            <StateSelector
                              selectedState={formValues?.address?.state}
                              onChange={handleAddressChange}
                              errors={errors}
                            />
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">City</label>
                          </div>
                          <div className="col-md-8">
                            <CitySelector
                              selectedCity={formValues?.address?.city}
                              onChange={handleAddressChange}
                              errors={errors}
                            />
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              PIN Code <span style={{ color: "red" }}> *</span>
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="zip"
                              value={formValues?.address?.["zip"] || ""}
                              onChange={handleAddressChange}
                              placeholder="Select PIN code"
                              required
                            ></input>
                            <div>
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"zip"}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Street <span style={{ color: "red" }}> *</span>
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="line_1"
                              value={formValues?.address?.["line_1"] || ""}
                              onChange={handleAddressChange}
                              placeholder="Enter the address of your shop"
                              required
                            ></input>
                            <div>
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"line_1"}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Nearby Landmark <span style={{ color: "red" }}> *</span>
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="line_2"
                              value={formValues?.address["line_2"] || ""}
                              onChange={handleAddressChange}
                              placeholder="Enter landmarks near your your shop"
                              required
                            ></input>
                          </div>

                          {/* <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Listing type
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <ListSelector
                              selectedList={formValues.entity_type}
                              onChange={handleChange}
                              errors={errors}
                            />
                          </div> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="row">
            <div className="col-lg-10 col-md-10">
              <div className="card">
                <Coordinates
                  latitude={latitude}
                  longitude={longitude}
                  setCoordinates={setCoordinates}
                />
                <MapComponent
                  setLatitude={setLatitude}
                  latitude={latitude}
                  longitude={longitude}
                  setLongitude={setLongitude}
                  showLatitude={showLatitude}
                  showLongitude={showLongitude}
                  setShowLatitude={setShowLatitude}
                  setShowLongitude={setShowLongitude}
                />
              </div>
            </div>
          </div>
        </section>

        <section id="basic-horizontal-layouts">
          <div className="match-height row">
            <div className="col-lg-10 col-md-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Upload Images</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form-horizontal form">
                      <div className="form-body">
                        <div className="row">
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Upload Cover Image
                            </label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                            <fieldset>
                              <SingleImageSelector
                                imageToShow={featuredImage}
                                onChangeImage={onChangeFeaturedImage}
                              />
                            </fieldset>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Upload Other Images
                            </label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                            <fieldset>
                              <MultiImageSelector
                                selectedImages={selectedImages}
                                setSelectedImages={setSelectedImages}
                              />
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="basic-horizontal-layouts">
          <div className="match-height row">
            <div className="col-lg-10 col-md-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">SEO Information</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form-horizontal form">
                      <div className="form-body">
                        <div className="row">
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">Meta Title</label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                            <input
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="meta_title"
                              value={
                                formValues?.meta_title != undefined
                                  ? formValues?.meta_title
                                  : formValues?.name
                              }
                              onChange={handleChange}
                              maxLength={60}
                            ></input>
                           <p>Maximum character :60</p>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Meta Description
                            </label>
                          </div>

                          <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                            <textarea
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="meta_description"
                              rows={4}
                              value={
                                formValues?.meta_description != undefined
                                  ? formValues?.meta_description
                                  : convertRichTextToPlainText(description)
                              }
                              onChange={handleChange}
                            />
                             
                             <div className="d-md-flex justify-content-between">
                             <div>Character length : {formValues?.meta_description?.length?? convertRichTextToPlainText(description)?.length??0}</div>
                             <div>Max length : 160</div>
                             </div>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Upload Meta Image
                            </label>
                          </div>
                          <div className="col-lg-8 col-md-8 col-xs-8 form-group">
                            <fieldset>
                              <SingleImageSelector
                                imageToShow={metaImage}
                                onChangeImage={onChangeMetaImage}
                              />
                            </fieldset>
                          </div>

                          {/* Button For Submit */}
                          <div className="d-flex justify-content-end col-sm-12">
                            <button
                              className="mb-1 btn btn-primary me-1"
                              onClick={handleShopSubmit}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default AddShop;
