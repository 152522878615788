import React, { useState, useEffect } from "react";
import { useUser } from "../../../utils/utils";
import loading_gif from "../../../assets/gif/loading_gif.gif";
import { callApi } from "../../api/Api";
import Logout from "../../auth/logout/Logout";
import { Link } from "react-router-dom";
import "./BannerList.css";

function BannerList() {
  const { user, setUser } = useUser();
  const [getBannerImages, setGetBannerImages] = useState();
  const [isLoading, setIsLoading] = useState(false);

  console.log("getBannerImages =>", getBannerImages);

  async function BannerImagesList() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user.area_id,
    };
    setIsLoading(true);
    try {
      const bannerImagesList = await callApi(
        `${baseUrl}/modules/areas/get-slider-images`,
        "POST",
        headers,
        payload
      );
      setGetBannerImages(bannerImagesList.record.slider_images);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    } finally {
      //turn on loader
      setIsLoading(false);
    }
  }

  useEffect(() => {
    BannerImagesList();
  }, []);

  return (
    <div>
      <div className="page-heading">
        <div className="page-title">
          <section className="section">
            <div className="mb-3">
              <h3>Banner Images</h3>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="dataTable-container">
                  <table className="table table-striped" id="table1">
                    <thead>
                      <tr>
                        <th className="table_head">S.No</th>
                        <th className="table_head">Name</th>
                        <th className="table_head">Banner Image</th>
                      </tr>
                    </thead>

                    <tbody>
                      {isLoading && (
                        <img
                          src={loading_gif}
                          className="loading_gif"
                          alt="Loading..."
                        />
                      )}

                      {getBannerImages?.map((data, index) => (
                        <tr key={index}>
                          <td>{index+1}</td>
                          <td>{data.name}</td>
                          <td>
                            <div>
                              <img
                                src={data.path}
                                alt="Banner Image"
                                style={{ width: "50px", height: "50px" }}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-end col-sm-12">
                              <Link
                                to={{
                                  pathname: "/banner-edit",
                                }}
                              >
                                <button className="mb-1 btn btn-success me-1">
                                  <i className="bi bi-pencil-square"></i>
                                </button>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default BannerList;
