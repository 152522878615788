import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useReducer } from 'react';
import './playground.css';
import { callApi } from '../components/api/Api';
import { useUser } from '../utils/utils';
import TextEditor from '../components/richtext/TextEditor';


const Playground1 = () => {
    const [value, setValue] = useState("");
    const onChangeTextEditorValue = (text) => {
        setValue(text)
    }
    console.log("value=>", value);


    return (
        <>
            <TextEditor onChangeTextEditorValue={onChangeTextEditorValue} />
        </>
    );
};

export default Playground1;
