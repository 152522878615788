import React, { useState } from "react";
import "./Header.css";
import logo from "../../assets/logo/logo.png";
import Sidebar from "../sidebar/Sidebar";
import SelectedAreaDropdown from "../selector/SelectedAreaDropdown";
import Logout from "../auth/logout/Logout";
import { useUser } from "../../utils/utils";

const HypsoHeader = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const { user } = useUser()

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const closeSidebar = () => {
    setHamburgerOpen(false);
  };

  return (
    <>
      <header className="">
        <a
          href="#"
          className="d-block burger-btn d-xl-none"
          onClick={toggleHamburger}
        >
          <i className="bi bi-justify fs-3"></i>
        </a>
      </header>
      {hamburgerOpen && <div className="overlay" onClick={closeSidebar}></div>}
      <div className={`sidebar ${hamburgerOpen ? "" : "hidden"}`}>
        <Sidebar />
      </div>

      <div className="d-flex justify-content-end row">
        <div className="mb-3 col-10 col-md-3">
          <SelectedAreaDropdown />
        </div>
        <div className="mb-3 col-2 col-md-1">
          {
            user?.token ? <Logout /> : <a
              name=""
              id=""
              className="btn btn-primary"
              href="/login"
              role="button"
            >Login</a
            >
          }
        </div>
      </div>
    </>
  );
};

export default HypsoHeader;
