import React, { useState } from "react";
import { useUser } from "../../../utils/utils";
import { callApi } from "../../api/Api";
import Swal from "sweetalert2";
import SingleImageSelector from "../../singleImageSelector/SingleImageSelector";
import { useNavigate } from "react-router-dom";
import Message from "../../errors/Message";

function AddUser() {
  const [formValues, setFormValues] = useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const { user, setUser } = useUser();
  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  // handleChange for featuredImages
  const onChangeFeaturedImage = (newlySelectedImage) => {
    // setFeaturedImage(newlySelectedImage.image_id)
    setFeaturedImage(newlySelectedImage);
  };

  // Toast for Shop Add
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    var payload = {
      ...formValues,
      profile_image: featuredImage.image_id,
      "is_active": true,
      "role_code":"CSTR"
    };
    console.log(payload);

    try {
      const UserResponse = await callApi(
        `${baseUrl}/modules/users/create`,
        "POST",
        headers,
        payload
      );
      console.log('UserResponse',UserResponse);
      

      if (UserResponse.success) {
        console.log("success", UserResponse);
        Toast.fire({
          icon: "success",
          title: "Data saved successfully",
        });
        navigate("/users");
      } else {
        setErrors(UserResponse.errors);
        console.error("Error: Response indicates failure", UserResponse);
        Toast.fire({
          icon: "error",
          title: UserResponse.message || "Failed to save data",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        icon: "error",
        title: "An error occurred while adding the user",
      });
    }
  };

  return (
    <div>
      <div className="page-heading">
        <div className="page-title">
          <section className="section">
            <div className="match-height row">
              <div className="col-lg-10 col-md-10">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">User Information</h4>
                  </div>
                  <div className="card-content">
                    <div className="card-body">
                      <form
                        className="form-horizontal form"
                        onSubmit={handleSubmit}
                      >
                        <div className="form-body">
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                First Name
                              </label>
                            </div>
                            <div className="col-md-8 form-group">
                              <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                className="form-control"
                                value={formValues?.first_name || ""}
                                onChange={handleChange}
                              />
                               <Message type={'error'} errors={errors} fieldName={'first_name'} />
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                Last Name
                              </label>
                            </div>
                            <div className="col-md-8 form-group">
                              <input
                                type="text"
                                id="last_name"
                                name="last_name"
                                className="form-control"
                                value={formValues?.last_name || ""}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                Phone
                              </label>
                            </div>
                            <div className="col-md-8 form-group">
                              <input
                                type="text"
                                id="phone"
                                name="phone"
                                className="form-control"
                                value={formValues?.phone || ""}
                                onChange={handleChange}
                              />
                               <Message type={'error'} errors={errors} fieldName={'phone'} /> 
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                Email
                              </label>
                            </div>
                            <div className="col-md-8 form-group">
                              <input
                                type="text"
                                id="email"
                                name="email"
                                className="form-control"
                                value={formValues?.email || ""}
                                onChange={handleChange}
                              />
                               <Message type={'error'} errors={errors} fieldName={'email'} />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="gender">Gender</label>
                            </div>
                            <div className="d-flex justify-content-start align-items-center gap-3 col-md-8 form-group">
                              <div className="d-flex gap-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="male"
                                  value="Male"
                                  checked={formValues.gender === "Male"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="male"
                                >
                                  Male
                                </label>
                              </div>

                              <div className="d-flex gap-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="female"
                                  value="Female"
                                  checked={formValues.gender === "Female"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="female"
                                >
                                  Female
                                </label>
                              </div>

                              <div className="d-flex gap-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="others"
                                  value="others"
                                  checked={formValues.gender === "others"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="others"
                                >
                                  Others
                                </label>
                              </div>
                            </div>


                            <div className="mt-2 col-md-4">
                              <label htmlFor="first-name-horizontal">
                                Upload Profile
                              </label>
                            </div>
                            <div className="mt-2 col-lg-8 col-md-8 col-xs-8 form-group">
                              <fieldset>
                                <SingleImageSelector
                                  imageToShow={featuredImage}
                                  onChangeImage={onChangeFeaturedImage}
                                />
                              </fieldset>
                            </div>
                            <div className="d-flex justify-content-end col-sm-12">
                              <button
                                type="submit"
                                className="mt-1 btn btn-primary me-1"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
