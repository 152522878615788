export const menuItemsData = [
  { title: "Dashboard", url: "/" ,
     role_code : ['ADMIN','S-ADM','A-ADM']
  },
  {
    title: "Shops",
    subMenu: [
      { title: "All", url: "/shops" },
      { title: "Add New", url: "/shop-new" },
    ],
    role_code : ['ADMIN','S-ADM','A-ADM']
  },
  {
    title: "Services",
    subMenu: [
      { title: "All", url: "/services" },
      { title: "Add New", url: "/service-new" },
    ],
    role_code : ['ADMIN','S-ADM','A-ADM']
  },

  {
    title: "Offers",
    subMenu: [
      { title: "All", url: "/offers" },
      { title: "Add New", url: "/offer-new" },
    ],
    role_code : ['ADMIN','S-ADM','A-ADM']
  },
  {
    title: "Events",
    subMenu: [
      { title: "All", url: "/events" },
      { title: "Add New", url: "/event-new" },
    ],
    role_code : ['ADMIN','S-ADM','A-ADM']
  },
  {
    title: "Reviews",url: "/reviews",
    role_code : ['ADMIN','S-ADM','A-ADM']
  },
  {
    title: "Property",
    subMenu: [
      { title: "All", url: "/properties" },
      { title: "Add New", url: "/property-new" },
    ],
    role_code : ['ADMIN','S-ADM','A-ADM']
  },
  {
    title: "Category",
    subMenu: [
      { title: "All", url: "/categories" },
      { title: "Add New", url: "/category-new" },
    ],
    role_code : ['ADMIN','S-ADM','A-ADM']
  },
  {
    title: "Banner",
    subMenu: [
      { title: "All", url: "/banner-list" },
      { title: "Add New", url: "/banner-new" }
    ],
    role_code : ['ADMIN','S-ADM','A-ADM']
  },

  {
    title: "Users",
    subMenu: [
      { title: "All", url: "/users" },
      { title: "Add New", url: "/user-new" },
    ],
    role_code : ['ADMIN','S-ADM','A-ADM']
  },
  {
    title: "Admin Users",
    subMenu: [
      { title: "All", url: "/admin-users" },
      { title: "Add New", url: "/admin-user-new" },
    ],
    role_code : ['ADMIN','S-ADM']
  },

];
