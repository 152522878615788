import { createContext } from "react"

export const UserContext = createContext({ user: { user_id: '', token: '', area_id: '', admin_area:'' }, setUser: () => { } })

export const PendingCountContext = createContext({
    pendingCount: {
        Shops: '',
        Services: '',
        Reviews: '',
        Property: '',
        Events: '',
        Offers: '',
    },
    setPendingCount: () => { }
})