import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useReducer } from 'react';
import { callApi } from '../../components/api/Api';
import { useUser } from '../../utils/utils';

const SearchableDropdown = ({ url, entity_type, setFormValues }) => {
    const { user } = useUser();
    const [categoryObject, setCategoryObject] = useState([]);
    const ref = useRef(null);
    const itemsRef = useRef([]);
    const initialState = {
        open: false,
        value: '',
        options: categoryObject,
        activeIndex: 0
    };
    const [{ open, value, activeIndex, options }, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        getCategory();
    }, [value]);

    useEffect(() => {
        if (categoryObject.length > 0) {
            dispatch({ type: 'resetOptions', options: categoryObject });
        }
    }, [categoryObject]);

    useEffect(() => {
        function toggle(e) {
            let onInput = e && e.target === ref.current;
            if (!onInput) {
                dispatch({ type: 'close' });
            }
        }
        document.addEventListener('click', toggle);
        return () => document.removeEventListener('click', toggle);
    }, []);

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, options.length);
    }, [options]);

    useEffect(() => {
        if (open && itemsRef.current[activeIndex]) {
            itemsRef.current[activeIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [activeIndex, open]);


    const handleSelect = (option) => {
        console.log("final Value", option);
        setFormValues(prev => ({ ...prev, category_id: option?.id }))
        dispatch({ type: 'select', payload: option });
    };

    function reducer(state, action) {
        switch (action.type) {
            case 'select':
                return {
                    ...state,
                    open: false,
                    value: action.payload.name, // Set value to selected category's name
                    options: categoryObject,
                };
            case 'toggle':
                return {
                    ...state,
                    open: !state.open,
                };
            case 'close':
                return {
                    ...state,
                    open: false,
                };
            case 'query':
                return {
                    ...state,
                    open: true,
                    value: action.value,
                    options: categoryObject.filter((category) =>
                        category.name.toLowerCase().includes(action.value.toLowerCase())
                    ),
                    activeIndex: 0
                };
            case 'resetOptions':
                return {
                    ...state,
                    options: action.options,
                    activeIndex: 0, // Reset activeIndex to the first item
                };

            case 'increment':
                return {
                    ...state,
                    activeIndex: (state.activeIndex + 1) % state.options.length
                }
            case "decrement":
                return {
                    ...state,
                    activeIndex:
                        (state.activeIndex - 1 + state.options.length) %
                        state.options.length
                };
            default:
                throw new Error('Unexpected action');
        }
    }


    async function getCategory() {
        var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

        var headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token: user?.token,
        };

        var payload = {
            page_size: 5000,
            "entity_type": entity_type,
            "filters": {
                "conditions": [
                    {
                        "field": "c.name",
                        "operator": "like",
                        "value": value,
                        "dataType": "string"
                    }
                ]
            }
        };
        try {
            const categoryData = await callApi(
                `${baseUrl}/modules/categories/${url}`,
                'POST',
                headers,
                payload
            );
            if (categoryData?.records?.length > 0) {
                const categories = categoryData.records.map((record) => ({
                    id: record.category_id,
                    name: record.name,
                }));
                setCategoryObject(categories);
            }
        } catch (error) {
            console.error('There was an error fetching the categories!', error);
        }
    }

    return (
        <>
            <div className="dropdown">
                <div
                    className="control"
                    onClick={() => {
                        dispatch({ type: 'toggle' });
                    }}
                >
                    <div className="selected-value">
                        <input
                            type="text"
                            value={value}
                            ref={ref}
                            onChange={(e) => {
                                dispatch({ type: 'query', value: e.target.value });
                            }}
                            onKeyDown={(event) => {
                                if (event.key === "Enter" && options[activeIndex]) {
                                    event.preventDefault();
                                    handleSelect(options[activeIndex]);
                                    ref.current.blur();
                                } else if (event.key === "ArrowUp") {
                                    event.preventDefault();
                                    dispatch({ type: "decrement" });
                                } else if (event.key === "ArrowDown") {
                                    event.preventDefault();

                                    dispatch({ type: "increment" });
                                }
                            }}
                        />
                    </div>
                    <div className={`arrow ${open ? 'open' : 'closed'}`} />
                </div>

                <div className={`options ${open ? 'open' : ''}`}>
                    {categoryObject.map((category, i) => {
                        let isActive = activeIndex === i
                        return (
                            <div
                                className={`option ${isActive ? "selected" : ""}`}
                                onClick={() => {
                                    handleSelect(category);
                                }}

                                ref={(el) => (itemsRef.current[i] = el)}
                                key={category.id}
                            >
                                {category.name}
                            </div>
                        )

                    })}
                </div>
            </div>
        </>
    )
}

export default SearchableDropdown
