import React, { useState, useEffect, useRef } from "react";
import logo from "../../../assets/logo/logo.png";
import { callApi } from "../../api/Api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useUser } from "../../../utils/utils";
import "../otpCapture/OTPCapture.css";
import Message from "../../errors/Message";
import Swal from "sweetalert2";

function OTPCapture() {
  const [userForm, setUserForm] = useState({});
  const { user, setUser } = useUser();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);
  const [errors, setErrors] = useState([]);
  const [userId, setUserId] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const login_email = searchParams.get("login_email") || "";
  const email = searchParams.get("email") || "";

  console.log("login_email", login_email);
  const [loading, setLoading] = useState(false);
  const eamil = searchParams.get("email");
  const [counter, setCounter] = useState(0); // Initially 0, only start on resend
  const [isResendClicked, setIsResendClicked] = useState(false); // Track if resend button clicked
  const [isResending, setIsResending] = useState(false); // Loader for resend

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else if (counter === 0) {
      setIsResendClicked(false); // Reset isResendClicked when countdown finishes
      setIsResending(false); // Stop the loader when the countdown ends
    }

    // Clear the interval when the component unmounts or counter reaches 0
    return () => clearInterval(timer);
  }, [counter]);

  const resendOtpCode = () => {
    if (!isResendClicked) {
      setCounter(60); // Set counter to 60 seconds when resending
      setIsResendClicked(true); // Start the countdown
      setIsResending(true); // Show loader while resending
      // Simulate API call to resend OTP
      if (eamil) {
        handleChangeOtp();
      }
      // if (email && fname && lname && phone) {
      //     handleSignUpOtp()
      // }
      setTimeout(() => {
        setIsResending(false); // Hide loader after simulated resend
      }, 2000); // Simulated API call delay (2 seconds)
    }
  };

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChangeOtp = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);
    if (value && index < 5 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleChange = (e) => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };

  // Toast for OTP
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const joinedOtp = otp.join("");

    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const payload = {
      otp_code: joinedOtp,
      email: email || login_email,
    };

    console.log(payload);

    try {
      const response = await callApi(
        `${baseUrl}/modules/users/verify-otp`,
        "POST",
        headers,
        payload
      );
      console.log(response);

      if (response.success) {
        Toast.fire({
          icon: "success",
          title: "OTP verified successfully",
        });
        setUserId(response?.user_id);
        if (login_email) {
          navigate(`/set-new-password/${response?.user_id}`);
        } else {
          navigate(`/set-password/${response?.user_id}`);
        }
      } else {
        setErrors(response.errors);
      }
      console.log("response.errors", response.errors);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="row">
      <div className="mt-5 mb-5 col-12 col-md-5 login_col">
        <div id="auth-left">
          <div className="auth-logo">
            <a href="/">
              <img src={logo} alt="Logo" className="hypso_logo" />
            </a>
          </div>
          <h2 className="mt-3 auth-title">OTP verification</h2>
          {/* <form onSubmit={handleSubmit}> */}
          <form
            action="#"
            className="form-horizontal form"
            onSubmit={handleSubmit}
          >
            <div className="form-body">
              <p className="auth-subtitle">Enter the otp verification code.</p>
              <div className="position-relative otp-container form-group">
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    ref={(input) => (inputRefs.current[index] = input)}
                    value={value}
                    onChange={(e) => handleChangeOtp(index, e)}
                    onClick={() => handleClick(index)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    className="form-control form-control-xl otp-input"
                  />
                ))}
              </div>
              <div className="mb-4 text-end">

                <span
                  onClick={() => resendOtpCode()}
                  className="mb-0 p-0 text-decoration-underline btn btn-link btn-sm"
                >
                  {counter > 0 ? (
                    <>
                      Resend in {counter}s
                      {counter && (
                        <div
                          className="spinner-border-sm spinner-border text-primary ms-2"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </>
                  ) : (
                    "Click to resend code?"
                  )}
                </span>
              </div>
              <div className="mb-2">
                <Message
                  type={"error"}
                  errors={errors}
                  fieldName={"otp_code"}
                />
              </div>
            </div>
            <button
              className="btn-block shadow-lg mt-2 btn btn-lg btn-primary"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default OTPCapture;
