import React from "react";
import { menuItemsData } from "../sidebar/MenuItemsData";
import MenuItem from "./MenuItem";
import "./sidebar.css";
import hypso_logo from "../../assets/logo/logo.png";
import { Link } from "react-router-dom";
import { useUser } from "../../utils/utils";
import { getTokenDetails } from "../../utils/tokenUtils";

const Sidebar = () => {
  const { user } = useUser();
  const tokenDetails = getTokenDetails(user?.token); // Decode the token
  const userRole = tokenDetails?.role_code; // Extract the role from token

  // Filter menuItemsData based on userRole
  const filteredMenuItems = menuItemsData.filter((menuItem) => {
    // Ensure role_code is always an array (even if it's a single string)
    const roleCode = Array.isArray(menuItem.role_code)
      ? menuItem.role_code
      : [menuItem.role_code];

    // Check if the user's role is in the role_code of the menu item
    return roleCode.includes(userRole);
  });


  return (
    <div id="sidebar">
      <div className="active sidebar-wrapper">
        <div className="position-relative sidebar-header">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo">
              <Link to="/" className="hypso_title">
                <img src={hypso_logo} className="hypso-logo-sidebar" />
              </Link>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul className="menu">
            {filteredMenuItems.map((menuItem, index) => (
              <div key={index}>
                <MenuItem menuItem={menuItem} index={index} />
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
