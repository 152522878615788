import React, { useEffect, useState } from "react";
import SidebarDropdown from "./SidebarDropdown";
import "./MenuItem.css";
import { useCount, useUser } from "../../utils/utils";
import { callApi } from "../api/Api";

const MenuItem = ({ menuItem, index }) => {

  const [dropdown, setDropdown] = useState(false);
  const { user } = useUser();
  const { pendingCount, setPendingCount } = useCount();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user?.area_id) {
      pendingListingsCount();
    }
  }, [user?.area_id]);

  const pendingListingsCount = async () => {
    if (!user?.area_id) {
      console.log("User or area_id is not available");
      return;
    }

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user?.area_id || 1,
      page_size: 10000,
      filters: {
        conditions: [
          {
            field: "ed.status",
            operator: "like",
            value: "STS_PENDING",
            dataType: "string",
          },
        ],
      },
    };
    const review_payload = {
      area_id: user?.area_id || 1,
      page_size: 10000,
      filters: {
        conditions: [
          {
            field: "status",
            operator: "like",
            value: "STS_PENDING",
            dataType: "string",
          },
        ],
      },
    };
    const offerpayload = {
      area_id: user?.area_id || 1,
      page_size: 10000,
      filters: {
        conditions: [
          {
            field: "offer_status",
            operator: "like",
            value: "STS_PENDING",
            dataType: "string",
          },
        ],
      },
    };
    const eventpayload = {
      area_id: user?.area_id || 1,
      page_size: 10000,
      filters: {
        conditions: [
          {
            field: "event_status",
            operator: "like",
            value: "STS_PENDING",
            dataType: "string",
          },
        ],
      },
    };

    setIsLoading(true);
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    try {
      const shopListData = await callApi(
        `${baseUrl}/modules/shops/get-shops`,
        "POST",
        headers,
        payload
      );
      const serviceListData = await callApi(
        `${baseUrl}/modules/services/get-services`,
        "POST",
        headers,
        payload
      );
      const propertyListData = await callApi(
        `${baseUrl}/modules/properties/get-properties`,
        "POST",
        headers,
        payload
      );
      const offerListData = await callApi(
        `${baseUrl}/modules/offers/get-offers`,
        "POST",
        headers,
        offerpayload
      );
      const eventListData = await callApi(
        `${baseUrl}/modules/events/get-events`,
        "POST",
        headers,
        eventpayload
      );
      const reviewListData = await callApi(
        `${baseUrl}/modules/reviews/get-reviews`,
        "POST",
        headers,
        review_payload
      );
      setPendingCount({
        Shops: shopListData?.total_rows || "",
        Services: serviceListData?.total_rows || "",
        Property: propertyListData?.total_rows || "",
        Offers: offerListData?.total_rows || "",
        Events: eventListData?.total_rows || "",
        Reviews: reviewListData?.total_rows || "",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  const viewPendingCount = (count) => {
    // console.log("result",pendingCount?.[count]);
    return pendingCount?.[count] || "";
  };

  const hasSubMenu = menuItem?.subMenu && menuItem?.subMenu?.length > 0;

  return (
    <li
      className={`sidebar-item ${hasSubMenu ? "has-sub" : ""}`}
      onClick={toggleDropdown}
      key={index}
    >
      <a href={menuItem?.url} className="sidebar-link">
        {menuItem?.title}{" "}
        {`${
          pendingCount?.[menuItem?.title] &&
          viewPendingCount(menuItem?.title) > 0
            ? "(" + viewPendingCount(menuItem?.title) + ")"
            : ""
        }`}
      </a>
      {dropdown && hasSubMenu && (
        <SidebarDropdown submenus={menuItem?.subMenu} />
      )}
    </li>
  );
};

export default MenuItem;
