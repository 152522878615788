import React, { useState, useEffect, useRef } from "react";
import logo from "../../../assets/logo/logo.png";
import { callApi } from "../../api/Api";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../utils/utils";
import Message from "../../errors/Message";
import Swal from "sweetalert2";

const SetPassword = () => {
  const [userForm, setUserForm] = useState({});
  const { user, setUser } = useUser();
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const param = useParams();
  const id = param.id;

  const handleChange = (e) => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };

  // Toast for password
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const payload = {
      ...userForm,
      user_id: id,
    };

    try {
      const response = await callApi(
        `${baseUrl}/modules/users/reset-password`,
        "POST",
        headers,
        payload
      );
      console.log(payload);
      console.log(response);

      if (response.success) {
        Toast.fire({
          icon: "success",
          title: "Account created successfully",
        });
        navigate("/admin-users");
      } else {
        setErrors(response.errors);
      }
      console.log("response.errors", response.errors);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="mt-5 mb-5 col-12 col-md-5 login_col">
          <div id="auth-left">
            <div className="auth-logo">
              <a href="/">
                <img src={logo} alt="Logo" className="hypso_logo" />
              </a>
            </div>
            <h2 className="mt-3 auth-title">Set Password</h2>
            {/* <form onSubmit={handleSubmit}> */}
            <form
              action="#"
              className="form-horizontal form"
              onSubmit={handleSubmit}
            >
              <div className="form-body">
                <label htmlFor="">Enter Password</label>
                <div className="position-relative has-icon-left mb-2 form-group">
                  <input
                    name="password"
                    type="password"
                    value={userForm?.password}
                    className="form-control form-control-xl"
                    placeholder="Enter your password"
                    onChange={handleChange}
                  />
                  <div className="form-control-icon">
                    <i className="bi bi-shield-lock"></i>
                  </div>
                </div>
                
                <label htmlFor="">Confirm Password</label>
                <div className="position-relative has-icon-left mb-2 form-group">
                  <input
                    name="confirm_password"
                    type="password"
                    value={userForm?.confirm_password}
                    className="form-control form-control-xl"
                    placeholder="Enter your password"
                    onChange={handleChange}
                  />
                  <div className="form-control-icon">
                    <i className="bi bi-shield-lock"></i>
                  </div>
                </div>
                <Message
                  type={"error"}
                  errors={errors}
                  fieldName={"password"}
                />
              </div>
              <button
                className="btn-block shadow-lg mt-2 btn btn-lg btn-primary"
                type="submit"
              >
                Sumbit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetPassword;
