import React, { useState } from "react";
import { WorkingTime } from "./WorkingTime";

function WorkingHours({ workingHours, setWorkingHours }) {

  const updateWorkingHours = (index, updatedHours) => {
    setWorkingHours((prevState) => {
      const newState = [...prevState];

      // Update the current day's hours
      newState[index] = { ...newState[index], ...updatedHours };

      if (updatedHours.status) {
        // If the current day is checked, propagate its start and end times to other unchecked days
        newState.forEach((day, i) => {
          if (i !== index && !day.status) {
            newState[i].start = { ...updatedHours.start };
            newState[i].end = { ...updatedHours.end };
          }
        });
      }

      return newState;
    });
  };


  return (
    <>
      {workingHours.map((hours, index) => (
        <WorkingTime
          key={index}
          hours={hours}
          updateHours={(updateHours) => updateWorkingHours(index, updateHours)}
        />
      ))}
    </>
  );
}

export default WorkingHours;
