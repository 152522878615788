import React, { useEffect, useState } from "react";
import { defaultLocation } from "../../utils/locationUtils";
import { useUser } from "../../utils/utils";

const Coordinates = ({
  setCoordinates,
  latitude,
  longitude,
  coordinates,
  showLatitude,
  showLongitude,
}) => {
  useEffect(() => {
    if (latitude && longitude) {
      setCoordinates(`${latitude}, ${longitude}`);
    } else {
      setCoordinates(`${showLatitude}, ${showLongitude}`);
    }
  }, [latitude, longitude, setCoordinates]);

  return (
    <>
      <div className="d-flex align-items-center gap-3 card-header">
        <h5 className="m-0 card-title">Your Location</h5>
        <div className="align-items-center w-75 form-group location-container">
          <input
            id="latitude"
            value={coordinates}
            onChange={(e) => setCoordinates(e.target.value)}
            placeholder="Enter Latitude and Longitude (e.g., 11.0793, 76.9998)"
            type="text"
            className="form-control"
          />
        </div>
      </div>
    </>
  );
};

export default Coordinates;
