import React, { useState, useEffect } from "react";
import serviceImage from "../../../assets/ServiceImage/serviceImage.jpg";
import dateUtils from "../../../utils/dateUtils";
import { Link } from "react-router-dom";
import { callApi } from "../../api/Api";
import Swal from "sweetalert2";
import "./ServiceDataTable.css";
import { useCount, useUser } from "../../../utils/utils";
import Pagination from "../../pagination/Pagination";
import loading_gif from "../../../assets/gif/loading_gif.gif";
import customer_service from "../../../assets/png/customer_service.png"

const ServiceDataTable = () => {
  const [serviceListData, setServiceListData] = useState([]);
  const [status, setStatus] = useState("STS_LIVE");
  const { user } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [pendingCount, setPendingCount] = useState()
  const { pendingCount, setPendingCount } = useCount()
  const [search, setSearch] = useState("")

  const columnData = [
    "S.No",
    "Name",
    "Landmark",
    "Services",
    "Created Date",
  ];

  useEffect(() => {
    ServiceList();
  }, [currentPage, status,search]);

  async function ServiceList() {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      current_page: currentPage,
      area_id: user?.area_id,
      filters: {
        conditions: [
          {
            field: "ed.status",
            operator: "like",
            value: status,
            dataType: "string",
          },
          {
            "field": "s.name",
            "operator": "like",
            "value": search,
            "dataType": "string"
          }
        ],
      },
    };
    const pending_payload = {
      area_id: user.area_id,
      current_page: currentPage,
      filters: {
        conditions: [
          {
            field: "ed.status",
            operator: "like",
            value: "STS_PENDING",
            dataType: "string",
          },
        ],
      },
    };
    setIsLoading(true);
    try {
      const serviceList = await callApi(
        `${baseUrl}/modules/services/get-services`,
        "POST",
        headers,
        payload
      );
      const pendingServiceList = await callApi(
        `${baseUrl}/modules/services/get-services`,
        "POST",
        headers,
        pending_payload
      );
      setServiceListData(serviceList.records);
      setPendingCount({ ...pendingCount, Services: pendingServiceList?.total_rows })
      const pageTotal = serviceList.total_pages;
      setTotalPage(Array.from({ length: pageTotal }, (_, i) => i + 1));
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    } finally {
      setIsLoading(false);
    }
  }

  console.log("pending", pendingCount);


  // SweetAlert Toast For handleServiceDelete
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  //soft delete the particular service
  const handleServiceDelete = async (service_id) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmation.isConfirmed) {
      const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

      console.log("your service ID", service_id);
      const headers = {
        "Content-Type": "application/json",
        token: user?.token,
      };
      try {
        const ServiceDeleteResponse = await callApi(
          `${baseUrl}/modules/services/delete/${service_id}`,
          "POST",
          headers
        );
        if (ServiceDeleteResponse.success) {
          console.log("success", ServiceDeleteResponse);
          Toast.fire({
            title: "Deleted",
            icon: "success",
          });
          // Remove deleted service from the list

          ServiceList();
        } else {
          console.log("failed", ServiceDeleteResponse);
          Toast.fire({
            title: "Failed to delete service",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        Toast.fire({
          title: "An error occurred while deleting the service",
          icon: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3 row">
        <div className="d-flex justify-content-between align-items-center gap-2 col-12 col-md-12">
          <h3>Services</h3>
          <Link to={'/service-new'}>
          <button className="btn btn-primary btn-sm">Add Service</button>
          </Link>
        </div>

      </div>
      <div className="page-heading">
        <div className="page-title">
          <section className="section">
            <div className="card">
              <div className="card-body">
                <div className="dataTable-top d-flex justify-content-between mb-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="active nav-link"
                        id="home-tab"
                        data-bs-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={() => setStatus("STS_LIVE")}
                      >
                        Live
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => setStatus("STS_PENDING")}
                      >
                        Pending {`${pendingCount?.Services > 0 ? '(' + pendingCount?.Services + ')' : ''}`}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={() => setStatus("STS_REJECTED")}
                      >
                        Rejected
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={() => setStatus("STS_EXPIRED")}
                      >
                        Expired
                      </a>
                    </li>
                  </ul>
                  <div className="search-conatiner form-group">
                    <input type="text" value={search} onChange={e => setSearch(e.target.value)} className='form-control' placeholder='Search Service' name="" id="" />
                    <div className="search-icon">
                      <i className="bi bi-search"></i>
                    </div>
                  </div>
                </div>
                <div className="dataTable-container">
                  <table className="table table-striped" id="table1">
                    <thead>
                      <tr>
                        {columnData.map((col, i) => (
                          <th className="ta_head" key={i}>
                            {col}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {isLoading && (
                        <div className="spinner-border text-primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                      {serviceListData && serviceListData.length > 0 ? (
                        serviceListData?.map((service, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="text-capitalize">{service.name}</td>
                            <td>{service.line_1}{service.line_2? ', ':""}{service.line_2}{service.landmark? ', ':""}{service.landmark}</td>
                            <td>
                              <div>
                                <img
                                  src={service.featured_image_path ? service.featured_image_path : customer_service}
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                            </td>
                            <td>
                              {dateUtils.convertDateToString(
                                service.created_date_time
                              )}
                            </td>
                            <td>
                              <div className="d-flex justify-content-end col-sm-12">
                                <Link
                                  to={{
                                    pathname: `/service-edit/${service.service_id}`,
                                    state: service,
                                  }}
                                >
                                  <button className="mb-1 btn btn-primary me-1">
                                    <i className="bi bi-pencil-square"></i>
                                  </button>
                                </Link>
                                <button
                                  className="mb-1 btn btn-danger me-1"
                                  onClick={() =>
                                    handleServiceDelete(service.service_id)
                                  }
                                  title="Delete"
                                >
                                  <i className="bi bi-trash3"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={columnData.length}>No data found.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="dataTable-bottom d-flex justify-content-end">
                  {Array?.isArray(serviceListData) &&
                    totalPage?.length > 1 ? (
                    <Pagination
                      totalPage={totalPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ServiceDataTable;
