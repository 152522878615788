import React from "react";
import Message from "../../errors/Message";

const Input = ({ label, name, value, onChange, type, fieldName, errors }) => {

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12">
        <div className="mb-3">
          <label htmlFor="first-name-horizontal" className="form-label">
            {label}
          </label>

          <input
            type={type}
            name={name}
            className="form-control"
            value={value || ""}
            onChange={onChange}
            onWheel={(e)=>{e.preventDefault()}}
          />
          <Message
            type={"error"}
            errors={errors}
            fieldName={fieldName?.fieldName}
          />
        </div>
      </div>
    </div>
  );
};

export default Input;
